import graphics from '../core/gfx';
import IEntity from '../core/Entity.interface';

export default class Wall implements IEntity {
  ID: string = 'Wall_' + Math.ceil(Math.random() * Date.now()).toString(36);
  public destroyed: boolean = false;
  public hasPhysics: boolean = true;
  public isSolid: boolean = true;

  constructor(
    readonly x: number,
    readonly y: number,
    readonly width: number,
    readonly height: number
  ) {}

  public get position() {
    return { x: this.x, y: this.y };
  }

  update(): void {
    // graphics.beginFill(0x404040, 1);
    // graphics.drawRect(this.x, this.y, this.width, this.height);
    // graphics.endFill();
  }

  destroy(): void {
    this.destroyed = true;
  }
}
