export default Object.seal({
  name: 'needlegun',
  category: 'ranged',
  width: 18,
  height: 12,
  centerX: -8,
  centerY: 2,
  shotTimer: 6,
  dispersion: 2,
  sprite: 'assets/sprites/player/weapons/needle_gun.png',
  bulletSprite: 'assets/sprites/player/bullets/hand_gun_bullet.png',
  particle: 'dust',
  power: 1,
  bulletSpeed: 12,
  bulletFriction: 0,
  bulletScale: 0.8,
  shotSound: 'assets/sounds/player/weapons/hand_gun/hand_gun_shot.wav',
  // bulletSprite : love.graphics.newImage(
  //   'assets/sprites/player/weapons/bullets/needle_bullet.png'
  // ),
  bulletOffsetX: 1,
  bulletOffsetY: 1,
  kickBack: 5,
  magazineSize: 20,
  reloadTimer: 80,
  // magazineSprite : love.graphics.newImage(
  //   'assets/sprites/player/weapons/magazines/magazine_needle_gun.png'
  // ),
  // reloadSound : love.audio.newSource(
  //   love.sound.newSoundData(
  //     'assets/sfx/player/weapons/reloads/reload_needle_gun.mp3'
  //   )
  // ),
  bulletAmount: 1,
  bulletSpread: 0,
  bulletTimer: null,
  bulletWidth: 7,
  bulletHeight: 3,
  knockBack: 0,
  screenShake: 12,
});
