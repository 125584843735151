import IEntity from './Entity.interface';
import ICoordinates from '../core/Coordinates.interface';

export function hitTestRectangle(r1: IEntity, r2: IEntity): boolean {
  //Define the variables we'll need to calculate
  let hit,
    combinedHalfWidths,
    combinedHalfHeights,
    vx,
    vy,
    r1_centerX,
    r1_centerY,
    r2_centerX,
    r2_centerY,
    r1_halfWidth,
    r1_halfHeight,
    r2_halfWidth,
    r2_halfHeight;

  //hit will determine whether there's a collision
  hit = false;

  //Find the center points of each sprite
  r1_centerX = r1.x + r1.width / 2;
  r1_centerY = r1.y + r1.height / 2;
  r2_centerX = r2.x + r2.width / 2;
  r2_centerY = r2.y + r2.height / 2;

  //Find the half-widths and half-heights of each sprite
  r1_halfWidth = r1.width / 2;
  r1_halfHeight = r1.height / 2;
  r2_halfWidth = r2.width / 2;
  r2_halfHeight = r2.height / 2;

  //Calculate the distance vector between the sprites
  vx = r1_centerX - r2_centerX;
  vy = r1_centerY - r2_centerY;

  //Figure out the combined half-widths and half-heights
  combinedHalfWidths = r1_halfWidth + r2_halfWidth;
  combinedHalfHeights = r1_halfHeight + r2_halfHeight;

  //Check for a collision on the x axis
  if (Math.abs(vx) < combinedHalfWidths)
    if (Math.abs(vy) < combinedHalfHeights)
      //A collision might be occurring. Check for a collision on the y axis
      //There's definitely a collision happening
      hit = true;
    //There's no collision on the y axis
    else hit = false;
  //There's no collision on the x axis
  else hit = false;

  //`hit` will be either `true` or `false`
  return hit;
}

// export function boxesIntersect(a: any, b: any) {
//   const ab = a.getBounds();
//   const bb = b.getBounds();
//   return (
//     ab.x + ab.width > bb.x &&
//     ab.x < bb.x + bb.width &&
//     ab.y + ab.height > bb.y &&
//     ab.y < bb.y + bb.height
//   );
// }
//
//

export function lengthDirX(length: number, direction: number): Radians {
  return Math.cos(degToRad(direction)) * length;
}

export function lengthDirY(length: number, direction: number): Radians {
  return Math.sin(degToRad(direction)) * length;
}

export function directionTo(from: ICoordinates, to: ICoordinates): Degrees {
  return radToDeg(Math.atan2(to.y - from.y, to.x - from.x));
}

export function degToRad(degrees: number): Radians {
  return degrees * (Math.PI / 180);
}

export function radToDeg(radians: number): Degrees {
  return radians * (180 / Math.PI);
}

export function lerp(
  startValue: number,
  endValue: number,
  time: number
): number {
  if ((startValue > 0 && endValue > 0) || (startValue < 0 && endValue < 0))
    if (Math.abs(endValue - startValue) <= 0.001) return endValue;

  return startValue * (1.0 - time) + endValue * time;
}

export function inverseLerp(
  startValue: number,
  endValue: number,
  time: number
): number {
  return clamp((time - startValue) / (endValue - startValue), 0, 1);
}

export function clamp(value: number, min: number, max: number): number {
  return Math.min(max, Math.max(min, value));
}

export function pickRandomValue(values: any[]) {
  return values[Math.floor(Math.random() * values.length)];
}

export function randomFloatInRange(min: number, max: number): number {
  return Math.random() * (max - min + 1) + min;
}

export function roundDecimal(value: number): number {
  // if (value > 0) return Math.floor(value * 10) / 10;
  // else return Math.ceil(value * 10) / 10;
  return Math.round(value * 10) / 10;
}

export function getMatrixDistance(
  cell1: { x: number; y: number },
  cell2: { x: number; y: number }
): number {
  return Math.abs(cell1.x - cell2.x) + Math.abs(cell1.y - cell2.y);
}

export function calculateDistance(
  pos1: { x: number; y: number },
  pos2: { x: number; y: number }
): number {
  return Math.sqrt(Math.pow(pos2.y - pos1.y, 2) + Math.pow(pos2.x - pos1.x, 2));
}

export function randomValueInRange(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function varyValue(value: number, variance: number): number {
  return value + randomValueInRange(-variance, variance);
}

export function remapValue(
  value: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
): number {
  return outMin + ((value - inMin) / (inMax - inMin)) * (outMax - outMin);
}

export function valueIsInRange(
  value: number,
  min: number,
  max: number
): boolean {
  return value <= max && value >= min;
}

type Degrees = number;
type Radians = number;
