import * as PIXI from 'pixi.js';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from './config/constants';
import Camera from './core/Camera';
import app from './core/Game';
import Gfx from './core/gfx';
import InstancesPool from './core/InstancesPool';
import GameWorld from './entities/GameWorld';
import Player from './entities/Player';
import ParticlesManager from './core/ParticlesManager';
import { SimpleLightmapFilter } from 'pixi-filters';
import { Texture } from 'pixi.js';
import MiniMap from './gui/MiniMap';

// https://www.html5gamedevs.com/topic/16019-preload-all-textures/
const load = (app: PIXI.Application) => {
  return new Promise<void>((resolve) => {
    const assets = [
      'assets/p1-idle.png',
      'assets/bullet-1.png',
      'assets/shotgun.png',
      'assets/p1_idle.png',
      'assets/p1_run.png',
      'assets/tileset_dirt_alt.png',
      'assets/sprites/map_border.png',
      'assets/sprites/single_light_ray.png',

      'assets/sprites/player/weapons/hand_gun.png',
      'assets/sprites/player/weapons/needle_gun.png',
      'assets/sprites/player/weapons/shot_gun.png',
      'assets/sprites/player/weapons/rail_gun.png',
      'assets/sprites/player/weapons/baseball_bat.png',

      'assets/sprites/player/bullets/hand_gun_bullet.png',

      'assets/sprites/enemies/enemy_wizard/enemy_wizard_idle.png',
      'assets/sprites/enemies/enemy_wizard/enemy_wizard_run.png',
      'assets/sprites/enemies/enemy_wizard/enemy_wizard_arm.png',

      'assets/sprites/enemies/enemy_slime/enemy_slime_idle.png',

      'assets/sprites/enemies/bullets/enemy_bullet_1.png',
      'assets/sprites/enemies/bullets/enemy_bullet_2.png',
      'assets/sprites/enemies/bullets/enemy_bullet_2_spawn.png',

      'assets/sprites/decorations/spr_decoration_dirt_floor_1.png',
      'assets/sprites/decorations/spr_decoration_dirt_floor_2.png',
      'assets/sprites/decorations/spr_decoration_dirt_floor_3.png',
      'assets/sprites/decorations/spr_decoration_dirt_fragment.png',
      'assets/sprites/decorations/spr_decoration_dirt_wall_2.png',
      'assets/sprites/decorations/spr_decoration_dirt_wall_3.png',
      'assets/sprites/decorations/spr_decoration_dirt_floor_debris_1.png',
      'assets/sprites/decorations/spr_decoration_desert_wall_1.png',

      'assets/minimap_tiles.png',

      'assets/particles/particle_blood_splat.png',
      'assets/particles/particle_hit_dust.png',
      'assets/particles/particle_hit_circle.png',
    ];

    for (const asset of assets) app.loader = app.loader.add(asset);

    app.loader.load(() => resolve());
  });
};

// let gameWorld: GameWorld;

const main = async () => {
  PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
  PIXI.settings.ROUND_PIXELS = true;
  PIXI.settings.ANISOTROPIC_LEVEL = 0;
  // PIXI.settings.CREATE_IMAGE_BITMAP = true;

  // Display application properly
  app.renderer.view.style.position = 'absolute';
  app.renderer.view.style.display = 'block';
  app.renderer.view.style.transform = 'scale(0.8)';

  // View size = windows
  app.renderer.resize(SCREEN_WIDTH, SCREEN_HEIGHT);

  // Load assets
  await load(app);

  // gameWorld = new GameWorld(4, 4);
  // gameWorld.generate();
  GameWorld.generate();

  InstancesPool.add(new Player(20, 20));

  document.body.appendChild(app.view);

  // app.stage.addChild(Gfx);
  Camera.viewport.addChild(Gfx);

  ParticlesManager.setup();

  Camera.viewport.addChild(ParticlesManager.container);
  ParticlesManager.container.zIndex = 100;

  // add the viewport to the stage
  app.stage.addChild(Camera.viewport);

  Camera.viewport.filters = [
    new SimpleLightmapFilter(
      Texture.from('assets/misc/light-map-2.png'),
      [0, 0, 0, 0.01]
    ),
  ];

  app.ticker.add(update);
};

var elapsed = Date.now();

// Cannot be an arrow function. Arrow functions cannot have a 'this' parameter.
function update(this: any, _delta: number) {
  Gfx.clear();

  const now = Date.now();

  ParticlesManager.setDeltaTime((now - elapsed) * 0.001);
  ParticlesManager.update();

  elapsed = now;

  InstancesPool.update();

  MiniMap.update();
}

main();
