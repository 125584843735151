import { cloneDeep, random, range } from 'lodash';
import WorldGenerator from './WorldGenerator';
import WORLD_SEEDS from '../data/world/seeds';
import Gfx from '../core/gfx';
import MapTypes from '../data/world/map-types';
import GameMapGenerator from '../procgen/MapGenerator';
import InstancesPool from '../core/InstancesPool';
import { CELL_SIZE } from '../config/constants';

class GameWorld {
  public structure: any[][] = [[]];
  public width: number = 0;
  public height: number = 0;
  public currentAreaX: number = 0;
  public currentAreaY: number = 0;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;

    window.addEventListener('keypress', (key) => {
      let x = this.currentAreaX;
      let y = this.currentAreaY;

      if (key.code === 'KeyL') x++;
      else if (key.code === 'KeyH') x--;
      else if (key.code === 'KeyJ') y--;
      else if (key.code === 'KeyK') y++;
      else return;

      this.goToArea(y, x, false);
    });
  }

  public generate() {
    console.debug('Generating world...');

    const worldGenStart: number = Date.now();

    // Instantiate the world generator (todo: might be a static class)
    // and generate the world structure
    this.structure = WorldGenerator.generate(
      this.structure,
      WORLD_SEEDS[random(0, WORLD_SEEDS.length - 1, false)]
    );

    const worldGenEnd: number = Date.now();

    console.debug(`World generated in ${worldGenEnd - worldGenStart}ms.`);

    console.debug('Generating maps...');

    const mapsGenStart: number = Date.now();

    this.structure = this.generateMaps(this.structure);

    const mapsGenEnd: number = Date.now();

    console.debug(`Maps generated in ${mapsGenEnd - mapsGenStart}ms.`);

    const startingArea = this.pickStartingArea();

    console.debug(
      `Picked starting map y: ${startingArea.y}, x: ${startingArea.x}`
    );

    this.goToArea(startingArea.y, startingArea.x, false);
  }

  private generateMaps(structure: any[][]) {
    const _structure = cloneDeep(structure);

    // Don't need to generate a map for "void" map cells
    for (const y of range(0, _structure.length))
      for (const x of range(0, _structure[0].length))
        if (_structure[y][x] !== MapTypes.Void) {
          _structure[y][x] = GameMapGenerator.generate(
            48, //64,
            48, //64,
            y,
            x,
            this.structure
          );

          // console.debug(
          //   `Map [${y}][${x}] generated (WxH: ${_structure[y][x].structure[0].length}x${_structure[y][x].structure.length}).`
          // );
        }

    return _structure;
  }

  get currentArea() {
    return this.structure[this.currentAreaY][this.currentAreaX];
  }

  public goToArea(y: number, x: number, relative: boolean = true) {
    // Remove all the instances from the map that's being left
    if (this.currentArea.removeOwnObjects) this.currentArea.removeOwnObjects();
    if (this.currentArea.registerTiles) this.currentArea.unregisterTiles();

    if (relative) {
      console.debug(
        `Moving to area y:${this.currentAreaY + y}, x:${this.currentAreaX + x}`
      );
      this.currentAreaX += x;
      this.currentAreaY += y;
    } else {
      console.debug(`Moving to area y:${y}, x:${x}`);
      this.currentAreaX = x;
      this.currentAreaY = y;
    }

    if (this.currentArea === MapTypes.Void)
      throw new Error('Trying to move to void area.');

    this.currentArea.spawnWalls();
    this.currentArea.spawnExitPoints();
    this.currentArea.spawnPopulation();
    this.currentArea.spawnDecorations();
    this.currentArea.setExplored();
    this.currentArea.registerTiles();
    this.currentArea.spawnOverlays();
    // this.structure[this.currentAreaY][this.currentAreaX].playBiomeAmbience(();
    //

    const areaHeight = this.currentArea.structure.length * CELL_SIZE;
    const areaWidth = this.currentArea.structure[0].length * CELL_SIZE;

    const teleportX =
      x === 0 || !relative
        ? areaWidth / 2
        : x < 0
        ? areaWidth - CELL_SIZE * 4
        : CELL_SIZE * 2;
    const teleportY =
      y === 0 || !relative
        ? areaHeight / 2
        : y < 0
        ? areaHeight - CELL_SIZE * 4
        : CELL_SIZE * 2;

    setTimeout(() => {
      if (InstancesPool.p1) InstancesPool.p1.teleportTo(teleportX, teleportY);
    }, 50);
  }

  public debug() {
    // for (const y of range(0, this.structure.length))
    //   for (const x of range(0, this.structure[0].length)) this.debugCell(x, y);
    // const map = this.structure[2][2].structure;
    // if (map)
    //   for (const y of range(0, map.length))
    //     for (const x of range(0, map[0].length)) this.debugCell(map, x, y);
  }

  debugCell(map: any[][], x: number, y: number) {
    const cellsize = 8;

    if (map[y][x] === MapTypes.Void) Gfx.beginFill(0x402000, 1);
    else Gfx.beginFill(0xfa00fa, 1);

    Gfx.drawRect(
      32 + x * cellsize,
      32 + y * cellsize,
      cellsize - 2,
      cellsize - 2
    );

    Gfx.endFill();
  }

  pickStartingArea() {
    while (true) {
      for (const y of range(0, this.structure.length))
        for (const x of range(0, this.structure[0].length))
          if (
            this.structure[y][x] !== MapTypes.Void &&
            Math.ceil(Math.random() * 6) === 6
          )
            return { x, y };
    }
  }
}

export default new GameWorld(4, 4);
