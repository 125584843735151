import { Container, Texture } from 'pixi.js';
import { Emitter, EmitterConfigV3 } from '@pixi/particle-emitter';
import ParticlesData from '../data/particles';
import AnimatedSpritesFactory from './AnimatedSpritesFactory';
import app from './Game';

type StaticParticleEmitterOpts = {
  spritePath: string;
  animated: false;
};

type AnimatedParticleEmitterOpts = {
  spritePath: string;
  animated: true;
  size: { width: number; height: number };
  frames: number;
  frameRate: number;
};

export default class ParticlesManager {
  public static emitters: Map<string, Emitter> = new Map();
  public static container: Container = new Container();
  private static deltaTime: number = Date.now();

  private static setUpEmitter(
    emitterID: string,
    opts: StaticParticleEmitterOpts | AnimatedParticleEmitterOpts
  ): void {
    this.emitters.set(
      emitterID,
      new Emitter(
        this.container,
        this[
          opts.animated
            ? 'buildAnimatedParticleConfiguration'
            : 'buildStaticParticleConfiguration'
        ](
          // @ts-ignore
          ParticlesData[emitterID],
          // @ts-ignore
          opts
        )
      )
    );
  }

  public static setup(): void {
    this.setUpEmitter('dot', {
      animated: false,
      spritePath: 'assets/particle_dot.png',
    });

    this.setUpEmitter('blood-splat', {
      animated: true,
      spritePath: 'assets/particles/particle_blood_splat.png',
      size: { width: 38, height: 33 },
      frames: 7,
      frameRate: 21,
    });

    this.setUpEmitter('hit-dust', {
      animated: true,
      spritePath: 'assets/particles/particle_hit_dust.png',
      size: { width: 11, height: 11 },
      frames: 5,
      frameRate: 20,
    });

    this.setUpEmitter('hit-circle', {
      animated: true,
      spritePath: 'assets/particles/particle_hit_circle.png',
      size: { width: 17, height: 17 },
      frames: 2,
      frameRate: 12,
    });

    this.setUpEmitter('player-weapon-spark', {
      animated: false,
      spritePath: 'assets/particle_player_weapon_spark.png',
    });

    this.container.zIndex = 20000;
  }

  public static setDeltaTime(deltaTime: number): void {
    this.deltaTime = deltaTime;
  }

  public static update(): void {
    for (const emitterID of Array.from(this.emitters.keys()))
      this.emitters.get(emitterID)?.update(this.deltaTime);
  }

  public static emit(
    emitterID:
      | 'dot'
      | 'player-weapon-spark'
      | 'blood-splat'
      | 'hit-dust'
      | 'hit-circle',
    opts: {
      position: { x: number; y: number };
      radius: number;
    }
  ): void {
    if (!this.emitters.get(emitterID)) return;

    // TODO: This `-200` is totally, TOTALLY casual. Find out why it works.
    this.emitters
      .get(emitterID)!
      .updateSpawnPos(opts.position.x - 200, opts.position.y - 200);

    this.emitters.get(emitterID)!.resetPositionTracking();

    this.emitters.get(emitterID)!.playOnce();
    this.emitters.get(emitterID)!.emit = true;
  }

  private static buildStaticParticleConfiguration(
    baseConfiguration: EmitterConfigV3,
    opts: StaticParticleEmitterOpts
  ): EmitterConfigV3 {
    return {
      ...baseConfiguration,
      behaviors: [
        ...baseConfiguration.behaviors,
        {
          type: 'textureSingle',
          config: {
            texture: Texture.from(opts.spritePath),
          },
        },
      ],
    };
  }

  private static buildAnimatedParticleConfiguration(
    baseConfiguration: EmitterConfigV3,
    opts: AnimatedParticleEmitterOpts
  ): EmitterConfigV3 {
    return {
      ...baseConfiguration,
      behaviors: [
        ...baseConfiguration.behaviors,
        {
          // type: 'animatedRandom',
          type: 'animatedSingle',
          config: {
            anim: {
              framerate: opts.frameRate,
              loop: false,
              textures: AnimatedSpritesFactory.generateAnimationTextures({
                ...opts.size,
                frames: opts.frames,
                spriteSheet:
                  app.loader.resources[opts.spritePath].texture!.baseTexture,
              }),
            },
          },
        },
      ],
    };
  }
}
