import graphics from '../core/gfx';
import IEntity from '../core/Entity.interface';
import InstancesPool from '../core/InstancesPool';
import World, { objectIsInLOS } from '../core/World';
import {
  calculateDistance,
  degToRad,
  directionTo,
  lengthDirX,
  lengthDirY,
  lerp,
  pickRandomValue,
  varyValue,
} from '../core/utils';
import EnemyStateData, {
  EnemyStateID,
  EnemyStatePropID,
} from '../data/enemy-state';
import Bullet from './Bullet';
import AnimatedSpritesFactory from '../core/AnimatedSpritesFactory';
import { AnimatedSprite, BaseTexture, Sprite, Texture } from 'pixi.js';
import app from '../core/Game';
import ParticlesManager from '../core/ParticlesManager';
import BulletSpawnFX from './BulletSpawnFX';

export default class EnemySlime implements IEntity {
  ID: string =
    'EnemySlime_' + Math.ceil(Math.random() * Date.now()).toString(36);
  public x: number;
  public y: number;
  width: number = 18;
  height: number = 12;
  vSpeed: number = 0;
  hSpeed: number = 0;
  private speed: number = 2;
  public sprite: AnimatedSprite;
  public sprites: { [spriteID: string]: Texture[] } = {};
  private friction: number = 0.2;
  private acceleration: number = 0.64;
  private deceleration: number = 0.64;
  private direction: number = 0.64;
  // sprite: AnimatedSprite;
  public destroyed: boolean = false;
  public hasPhysics: boolean = true;
  public state: EnemyStateID = 'idle';
  private hScale: number = 1;
  private vScale: number = 1;
  private scale: number = 1;
  private facingDirection: number = 1;
  private angle: number = 0;
  private phase: number = 0;
  private timer: number = 0;
  private hp: number = 10;
  private jumpHeight: number = 0;
  private jumpProgress: number = 0;
  private targetX: number = 0;
  private targetY: number = 0;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;

    this.sprites = AnimatedSpritesFactory.generateAnimationsTexturesMap({
      Idle: {
        spriteSheet:
          app.loader.resources[
            'assets/sprites/enemies/enemy_slime/enemy_slime_idle.png'
          ].texture!.baseTexture,
        width: 18,
        height: 12,
        frames: 4,
      },
      Run: {
        spriteSheet:
          app.loader.resources[
            'assets/sprites/enemies/enemy_slime/enemy_slime_idle.png'
          ].texture!.baseTexture,
        width: 18,
        height: 12,
        frames: 4,
      },
    });

    this.sprite = new AnimatedSprite(this.sprites.Idle);
    this.sprite.animationSpeed = 0.12;
  }

  get position() {
    return { x: this.x, y: this.y };
  }

  update(): void {
    if (this.angle !== 0) this.angle = lerp(this.angle, 0, 0.1);
    if (this.scale !== this.facingDirection)
      this.scale = lerp(this.scale, this.facingDirection, 0.05);

    if (this.hScale !== 1) this.hScale = lerp(this.hScale, 1, 0.2);
    if (this.vScale !== 1) this.vScale = lerp(this.vScale, 1, 0.1);

    this.move();

    this.handleState();

    this.sprite.anchor.set(this.facingDirection === 1 ? 0 : 1, 0);

    this.draw();
  }

  private move(): void {
    if (this.state !== 'dead')
      if (this.targetX && this.targetY) {
        this.x = lerp(this.x, this.targetX, 0.05);
        this.y = lerp(this.y, this.targetY, 0.05);
      }

    const {
      x: newX,
      y: newY,
      collisions,
    } = World.move(
      this.ID,
      this.x + this.hSpeed,
      this.y + this.vSpeed,
      (_, otherID) =>
        InstancesPool.get(otherID)?.constructor?.name === 'Bullet'
          ? 'cross'
          : 'slide'
    );

    this.x = newX;
    this.y = newY;

    this.sprite.x = this.x;
    this.sprite.y = this.y;

    for (const collision of collisions) this.collide(collision.other);
  }

  private collide(otherID: string) {
    const other = InstancesPool.get(otherID);

    if (!other || this.state === 'dead') return;

    if (
      other.constructor?.name === 'Bullet' &&
      (other as any).owner !== 'enemy'
    ) {
      other.destroy();

      ParticlesManager.emit('hit-circle', {
        position: {
          x: (this.centerX + other.position.x) / 2,
          y: (this.centerY + other.position.y) / 2,
        },
        radius: 8,
      });

      ParticlesManager.emit('dot', {
        position: {
          x: (this.centerX + other.position.x) / 2,
          y: (this.centerY + other.position.y) / 2,
        },
        radius: 24,
      });

      if (this.hp-- <= 0) {
        this.hSpeed = Math.sign((other as Bullet).hSpeed) * 2;
        this.angle = this.hSpeed * 25;

        this.goToState('dead');
      }
    }
  }

  private handleState() {
    // @ts-ignore
    this[`${this.state.toLowerCase()}State`]();
  }

  private deadState(): void {
    if (this.phase === 0) {
      this.jumpProgress = 0.11;
      this.phase++;
    }

    if (this.phase === 1) {
      this.jumpProgress += 5;

      this.jumpHeight = Math.sin(degToRad(this.jumpProgress)) * 20;

      // If has touched the ground during its fall
      if (this.jumpHeight <= 0.1) {
        // Camera.shake(40);

        for (const _ of [1, 2, 3, 4])
          ParticlesManager.emit('hit-dust', {
            position: {
              x: this.centerX,
              y: this.centerY,
            },
            radius: 8,
          });

        this.destroy();
      }
    }
  }

  private getStateProp(stateID: EnemyStateID, propID: EnemyStatePropID) {
    return EnemyStateData.EnemySlime[stateID][propID];
  }

  private idleState(): void {
    if (this.phase === 0) {
      this.timer = this.getStateProp('idle', 'timer');
      this.speed = 0;
      this.phase++;

      return;
    }

    if (this.phase === 1) {
      this.timer--;

      if (this.timer <= 0) this.goToState(pickRandomValue(['idle', 'roam']));
      else if (
        objectIsInLOS(
          this.ID,
          InstancesPool.p1.ID,
          this.getStateProp('chase', 'range')
        )
      )
        this.goToState('chase');

      return;
    }
  }

  private roamState(): void {
    if (this.phase === 0) {
      this.timer = this.getStateProp('roam', 'timer');
      this.direction = Math.random() * 360;
      this.facingDirection = this.hSpeed >= 0 ? 1 : -1;
      this.phase++;

      return;
    }

    if (this.phase === 1) {
      this.timer--;

      this.facingDirection = this.hSpeed >= 0 ? 1 : -1;

      if (this.timer <= 0) this.goToState(pickRandomValue(['idle', 'roam']));
      else if (
        objectIsInLOS(
          this.ID,
          InstancesPool.p1.ID,
          this.getStateProp('chase', 'range')
        )
      )
        // If player is close enough and line of sight is clear then start chasing
        this.goToState('chase');
    }
  }

  private chaseState(): void {
    if (this.phase === 0) {
      this.timer = varyValue(this.getStateProp('chase', 'timer'), 2);

      this.jumpProgress = 0;

      this.phase++;
    }

    if (this.phase === 1) {
      this.timer--;

      this.hScale = lerp(this.hScale, 1.2, 0.2);
      this.vScale = lerp(this.vScale, 0.8, 0.2);

      this.jumpHeight -= 0.1;

      if (this.timer <= this.getStateProp('chase', 'timer') - 20) {
        this.direction = directionTo(this.position, InstancesPool.p1.position);
        this.facingDirection = this.x <= InstancesPool.p1.x ? 1 : -1;

        this.targetX = this.x + lengthDirX(32, this.direction);
        this.targetY = this.y + lengthDirY(24, this.direction);

        this.phase++;
      }
    }

    if (this.phase === 2) {
      // if (this.jumpProgress < 10) {
      this.hScale = 0.6;
      this.vScale = 1.4;
      // }

      if (this.jumpProgress < 180) {
        this.jumpProgress += 5;
        this.jumpHeight = Math.sin(degToRad(this.jumpProgress)) * 20;
      } else {
        const shotDirection = directionTo(
          this.position,
          InstancesPool.p1.position
        );

        ParticlesManager.emit('hit-dust', {
          position: {
            x: this.centerX + lengthDirX(8, shotDirection),
            y: this.centerY + lengthDirY(4, shotDirection),
          },
          radius: 2,
        });

        this.shoot(
          this.centerX,
          this.centerY,
          2,
          0,
          shotDirection,
          'assets/sprites/enemies/bullets/enemy_bullet_2.png' // TODO: small bullet psrite
        );

        this.phase++;
      }
    }

    if (this.phase === 3) {
      this.jumpHeight = 0;

      this.hSpeed = this.vSpeed = 0;

      this.timer--;

      if (this.timer <= 0) {
        const distanceToP1 = calculateDistance(
          this.position,
          InstancesPool.p1.position
        );

        if (distanceToP1 >= this.getStateProp('chase', 'range'))
          this.goToState('idle');
        else if (distanceToP1 <= this.getStateProp('attack1', 'range'))
          this.goToState('chase');
        // attack1
        else this.goToState('chase');
      }
    }
  }

  shoot(
    x: number,
    y: number,
    speed: number,
    friction: number,
    direction: number,
    sprite: string
  ): void {
    InstancesPool.add(
      new Bullet({
        x,
        y,
        speed,
        direction,
        friction,
        sprite,
        angledSprite: false,
        owner: 'enemy',
      })
    );

    InstancesPool.add(new BulletSpawnFX(x, y, speed, direction));
  }

  private goToState(stateID: EnemyStateID) {
    if (this.state !== stateID) {
      if (stateID === 'chase' || stateID === 'roam')
        this.sprite.textures = this.sprites['Run'];
      else this.sprite.textures = this.sprites['Idle'];

      this.sprite.play();
    }

    this.state = stateID;
    this.phase = 0;
  }

  destroy(): void {
    this.destroyed = true;
  }

  drawShadow(): void {
    graphics.beginFill(0x000000, 0.15);
    graphics.drawEllipse(
      this.centerX,
      this.centerY + 5,
      this.width * 0.6 + this.jumpHeight / 12,
      2.4
    );
    graphics.endFill();
  }

  // @FeatureFlag('DEBUG_ENEMIES')
  draw(): void {
    this.drawShadow();

    this.sprite.scale.x = this.facingDirection * this.hScale;
    this.sprite.scale.y = this.vScale;
    this.sprite.play();
    this.sprite.y = this.y - this.jumpHeight;
    this.sprite.angle = lerp(this.sprite.angle, this.angle, 0.5);
    this.sprite.zIndex = this.y;
    // this.drawDebug();
  }

  drawDebug(): void {
    graphics.beginFill(0xde3249, 0.5);
    graphics.drawRect(this.x, this.y, this.width, this.height);
    graphics.endFill();

    graphics.lineStyle(1, 0xffaa00); //(thickness, color)
    graphics.drawCircle(this.x, this.y, this.getStateProp('chase', 'range'));
  }

  get centerX(): number {
    return this.x + this.width / 2;
  }

  get centerY(): number {
    return this.y + this.height / 2;
  }
}
