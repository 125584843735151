import { AnimatedSprite, Texture } from 'pixi.js';
import AnimatedSpritesFactory from '../core/AnimatedSpritesFactory';
import IEntity from '../core/Entity.interface';
import app from '../core/Game';
import { lengthDirX, lengthDirY } from '../lib/utils';

export default class BulletSpawnFX implements IEntity {
  public ID: string = Math.random().toString();
  public sprite: AnimatedSprite;
  public sprites: { [spriteID: string]: Texture[] } = {};
  public lifeTime: number = 0;
  public width: number = 2;
  public height: number = 2;
  public hasPhysics: boolean = false;
  public destroyed: boolean = false;

  constructor(
    public x: number,
    public y: number,
    public speed: number,
    public direction: number
  ) {
    this.sprites = AnimatedSpritesFactory.generateAnimationsTexturesMap({
      Base: {
        spriteSheet:
          app.loader.resources[
            'assets/sprites/enemies/bullets/enemy_bullet_2_spawn.png'
          ].texture!.baseTexture,
        width: 24,
        height: 24,
        frames: 5,
      },
    });

    this.sprite = new AnimatedSprite(this.sprites.Base);
    this.sprite.animationSpeed = 0.21;
    this.sprite.scale.set(0.9);
    this.width = this.sprite.width;
    this.height = this.sprite.height;
    this.sprite.anchor.set(0.5);
    this.sprite.angle = Math.random() * 360;
  }

  public get position() {
    return { x: this.x, y: this.y };
  }

  public update(): void {
    this.sprite.play();
    this.lifeTime++;

    this.x += lengthDirX(this.speed, this.direction);
    this.y += lengthDirY(this.speed, this.direction);

    this.sprite.x = this.x;
    this.sprite.y = this.y;

    console.log(this.sprite.currentFrame);

    if (this.sprite.currentFrame >= 4) this.destroy();
  }

  destroy() {
    this.destroyed = true;
  }
}
