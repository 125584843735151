import IEntity from '../core/Entity.interface';
import { BLEND_MODES, Sprite } from 'pixi.js';
import app from '../core/Game';
import Camera from '../core/Camera';
import { randomValueInRange } from '../lib/utils';

export default class LightRay implements IEntity {
  ID: string = 'LightRay_' + Math.ceil(Math.random() * Date.now()).toString(36);
  public destroyed: boolean = false;
  public hasPhysics: boolean = true;
  public sprite: Sprite;
  public alwaysUpdate: boolean = true;
  private offsetX: number = 0;
  private offsetY: number = 0;
  private maxAlpha = 0.06;
  private phase: 'fadeIn' | 'fadeOut' = 'fadeIn';

  constructor(
    public x: number,
    public y: number,
    readonly width: number,
    readonly height: number
  ) {
    this.sprite = new Sprite(
      app.loader.resources['assets/sprites/single_light_ray.png'].texture
    );

    this.sprite.blendMode = BLEND_MODES.SCREEN;
    this.sprite.alpha = 0;
    this.sprite.zIndex = 10000;
    this.sprite.scale.set(1.2 - Math.random() / 2);
    this.offsetX = randomValueInRange(0, 600);
  }

  public get position() {
    return { x: this.x, y: this.y };
  }

  update(): void {
    this.offsetX -= Math.random() / 20;
    this.offsetY -= Math.random() / 100;

    if (this.phase === 'fadeIn') {
      this.sprite.alpha += Math.random() / 200;

      if (this.sprite.alpha >= this.maxAlpha) this.phase = 'fadeOut';
    } else this.sprite.alpha -= Math.random() / 1000;

    this.x = Camera.viewport.left + this.offsetX;
    this.y = Camera.viewport.top + this.offsetY;

    this.sprite.x = this.x;
    this.sprite.y = this.y;

    if (this.phase === 'fadeOut' && this.sprite.alpha <= 0.01) this.destroy();
  }

  destroy(): void {
    this.destroyed = true;
  }
}
