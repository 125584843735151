import { cloneDeep, range } from 'lodash';
import {
  WORLD_SEED_EXPANSION_SIZE,
  WORLD_SEED_HEIGHT,
  WORLD_SEED_WIDTH,
} from '../config/constants';
import MapTypes from '../data/world/map-types';

export default class WorldGenerator {
  public static generate(structure: any[][], seed: number[][]): any[][] {
    const _structure = cloneDeep(structure);

    this.initialize(_structure);
    this.expandMatrix(_structure, seed);
    this.carveHoles(_structure, seed);
    this.removeSurroundedCells(_structure, seed);

    return _structure;
  }

  private static initialize(structure: any[][]) {
    for (const y of range(0, WORLD_SEED_HEIGHT * WORLD_SEED_EXPANSION_SIZE)) {
      structure[y] = [];

      for (const x of range(0, WORLD_SEED_WIDTH * WORLD_SEED_EXPANSION_SIZE))
        structure[y][x] = MapTypes.Void;
    }
  }

  private static expandMatrix(structure: any[][], seed: any[][]) {
    for (const y of range(0, seed.length))
      for (const x of range(0, seed[0].length))
        if (seed[y][x] === 1) this.expandArea(structure, x, y);
  }

  private static expandArea(structure: any[][], x: number, y: number) {
    const startX = WORLD_SEED_EXPANSION_SIZE * x;
    const startY = WORLD_SEED_EXPANSION_SIZE * y;
    const endX = startX + WORLD_SEED_EXPANSION_SIZE;
    const endY = startY + WORLD_SEED_EXPANSION_SIZE;

    for (const y of range(startY, endY))
      for (const x of range(startX, endX)) structure[y][x] = MapTypes.Pending;
  }

  private static carveHoles(structure: any[][], seed: any[][]) {
    const pickedCells: any[] = [];

    for (const y of range(0, seed.length * WORLD_SEED_EXPANSION_SIZE - 1))
      for (const x of range(
        0,
        seed[0].length * WORLD_SEED_EXPANSION_SIZE - 1
      )) {
        const currentCell = { x, y };

        if (
          Math.random() >= 0.2 &&
          structure[y][x] === MapTypes.Pending &&
          this.hasNoCellsNearby(
            currentCell,
            pickedCells,
            WORLD_SEED_EXPANSION_SIZE + 1
          )
        ) {
          this.carveHole(structure, x, y, WORLD_SEED_EXPANSION_SIZE - 1, seed);

          pickedCells.push(currentCell);
        }
      }
  }

  private static removeSurroundedCells(structure: any[][], seed: number[][]) {
    for (const y of range(0, seed.length * WORLD_SEED_EXPANSION_SIZE))
      for (const x of range(0, seed[0].length * WORLD_SEED_EXPANSION_SIZE))
        if (
          structure[y][x] !== MapTypes.Void &&
          this.isCellSurrounded(structure, { x, y }, seed)
        )
          structure[y][x] = MapTypes.Void;
  }

  private static carveHole(
    structure: any[][],
    startX: number,
    startY: number,
    size: number,
    seed: any[][]
  ) {
    for (const y of range(startY, startY + size))
      for (const x of range(startX, startX + size))
        if (
          x <= seed[0].length * WORLD_SEED_EXPANSION_SIZE - 1 &&
          y <= seed.length * WORLD_SEED_EXPANSION_SIZE - 1
        )
          structure[y][x] = MapTypes.Void;
  }

  private static hasNoCellsNearby(
    cell: { x: number; y: number },
    cells: { x: number; y: number }[],
    minimumDistance: number
  ) {
    if (cells.length === 0) return true;

    for (const _cell of cells)
      if (this.getCellDistance(cell, _cell) <= minimumDistance) return false;

    return true;
  }

  private static getCellDistance(
    cell1: { x: number; y: number },
    cell2: { x: number; y: number }
  ): number {
    return Math.abs(cell1.x - cell2.x) + Math.abs(cell1.y - cell2.y);
  }

  private static isCellSurrounded(
    structure: any[][],
    cell: { x: number; y: number },
    seed: number[][]
  ): boolean {
    for (const y of range(cell.y - 1, cell.y + 1 + 1))
      for (const x of range(cell.x - 1, cell.x + 1 + 1))
        if (
          x >= seed[0].length * WORLD_SEED_EXPANSION_SIZE ||
          y >= seed.length * WORLD_SEED_EXPANSION_SIZE ||
          x < 0 ||
          y < 0
        )
          return false;
        // Return false on the first non-filled surrounding cell
        // (Note that at this point all filled map cells have the "PENDING" mark,
        // while unfilled cells will be marked as "VOID")
        else if (structure[y][x] !== MapTypes.Pending) return false;

    return true;
  }
}
