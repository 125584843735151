import app from '../core/Game';
import graphics from '../core/gfx';
import IEntity from '../core/Entity.interface';
import InstancesPool from '../core/InstancesPool';
import World from '../core/World';
import PlayerWeapon from './PlayerWeapon';
import { AnimatedSprite, BaseTexture, Texture } from 'pixi.js';
import AnimatedSpritesFactory from '../core/AnimatedSpritesFactory';
import Camera from '../core/Camera';
import GameWorld from './GameWorld';
import ExitPoint from './ExitPoint';
import { Directions } from '../config/constants';

const StateData: { [stateID: string]: any } = {
  Idle: {
    animationSpeed: 0.04,
  },
  Run: { animationSpeed: 0.12 },
};

export default class Player implements IEntity {
  ID: string = Math.ceil(Math.random() * Date.now()).toString(36);
  public x: number;
  public y: number;
  width: number = 10;
  height: number = 14;
  vSpeed: number = 0;
  hSpeed: number = 0;
  sprite: AnimatedSprite;
  sprites: { [stateID: string]: Texture[] } = {};
  private speed: number = 2;
  private friction: number = 0.64;
  private inputMask = {
    up: false,
    down: false,
    left: false,
    right: false,
  };
  public destroyed: boolean = false;
  public hasPhysics: boolean = true;
  private state: string = 'Run';
  private bypassCollisions: boolean = false;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;

    this.sprites = AnimatedSpritesFactory.generateAnimationsTexturesMap({
      Idle: {
        spriteSheet: BaseTexture.from(
          app.loader.resources['assets/p1_idle.png'].url
        ),
        width: 10,
        height: 14,
        frames: 3,
      },
      Run: {
        spriteSheet: BaseTexture.from(
          app.loader.resources['assets/p1_run.png'].url
        ),
        width: 11,
        height: 14,
        frames: 3,
      },
    });

    this.sprite = new AnimatedSprite(this.sprites.Idle);
    this.sprite.animationSpeed = 0.08;

    window.addEventListener('keydown', this.handleKeyDown.bind(this));
    window.addEventListener('keyup', this.handleKeyUp.bind(this));

    InstancesPool.add(new PlayerWeapon(this.ID));
  }

  public get position() {
    return { x: this.x, y: this.y };
  }

  handleKeyDown(key: any): void {
    if (key.keyCode === 87) this.inputMask.up = true;
    if (key.keyCode === 83) this.inputMask.down = true;
    if (key.keyCode === 65) this.inputMask.left = true;
    if (key.keyCode === 68) this.inputMask.right = true;
  }

  handleKeyUp(key: any): void {
    if (key.keyCode === 87) this.inputMask.up = false;
    if (key.keyCode === 83) this.inputMask.down = false;
    if (key.keyCode === 65) this.inputMask.left = false;
    if (key.keyCode === 68) this.inputMask.right = false;
  }

  handleInput(): void {
    if (this.inputMask.up) this.vSpeed = -this.speed;
    if (this.inputMask.down) this.vSpeed = this.speed;
    if (this.inputMask.left) this.hSpeed = -this.speed;
    if (this.inputMask.right) this.hSpeed = this.speed;
  }

  move(): void {
    const {
      x: newX,
      y: newY,
      collisions,
    } = World.move(
      this.ID,
      this.x + this.hSpeed,
      this.y + this.vSpeed,
      (_itemID: IEntity['ID'], otherID: IEntity['ID']) => {
        if (InstancesPool.get(otherID)?.constructor?.name === 'Bullet')
          return 'cross';

        if (InstancesPool.get(otherID)?.isSolid) return 'slide';
        else return 'cross';
      }
    );

    this.x = newX;
    this.y = newY;

    this.sprite.x = this.x;
    this.sprite.y = this.y;

    if (this.friction) {
      this.hSpeed *= this.friction;
      this.vSpeed *= this.friction;

      if (Math.abs(this.hSpeed) < 0.01) this.hSpeed = 0;
      if (Math.abs(this.vSpeed) < 0.01) this.vSpeed = 0;
    } else this.hSpeed = this.vSpeed = 0;

    if (!this.bypassCollisions)
      for (const collision of collisions) this.collide(collision.other);
  }

  teleportTo(x: number, y: number): void {
    this.bypassCollisions = true;
    setTimeout(() => {
      this.bypassCollisions = false;
    }, 200);

    World.update(this.ID, x, y, this.width, this.height);

    this.x = x;
    this.y = y;

    this.sprite.x = this.x;
    this.sprite.y = this.y;

    console.debug(`Player teleported to y: ${y}, x: ${x}.`);
  }

  update(): void {
    this.handleInput();

    this.move();

    this.manageState();

    this.sprite.anchor.set(this.facingDirection === 1 ? 0 : 1, 0);

    this.draw();
    // this.drawDebug();
  }

  manageState(): void {
    if (Math.abs(this.hSpeed) + Math.abs(this.vSpeed) !== 0)
      this.setState('Run');
    else this.setState('Idle');
  }

  setState(newState: string): void {
    if (this.state !== newState) {
      this.state = newState;

      this.sprite.textures = this.sprites[newState];
      this.sprite.animationSpeed = StateData[newState].animationSpeed;
      this.sprite.play();
    }
  }

  get facingDirection(): number {
    return app.renderer.plugins.interaction.mouse.global.x >=
      Camera.viewport.toGlobal({ x: this.x, y: this.y }).x
      ? 1
      : -1;
  }

  draw(): void {
    this.drawShadow();

    this.sprite.zIndex = this.y;
    this.sprite.scale.x = this.facingDirection;
    this.sprite.play();
  }

  drawShadow(): void {
    graphics.beginFill(0x000000, 0.15);
    graphics.drawEllipse(
      this.centerX,
      this.centerY + 7,
      this.width * 0.8,
      this.height / 6
    );
    graphics.endFill();
  }

  drawDebug(): void {
    graphics.beginFill(0xde3249, 1);
    graphics.drawRect(this.x, this.y, this.width, this.height);
    graphics.endFill();
  }

  private collide(otherID: string) {
    if (this.bypassCollisions) return;

    const other = InstancesPool.get(otherID);

    if (!other) return;

    if (other.constructor?.name === 'ExitPoint') {
      const newX: number =
        (other as ExitPoint).direction === Directions.West
          ? -1
          : (other as ExitPoint).direction === Directions.East
          ? 1
          : 0;
      const newY: number =
        (other as ExitPoint).direction === Directions.North
          ? -1
          : (other as ExitPoint).direction === Directions.South
          ? 1
          : 0;

      this.bypassCollisions = true;

      GameWorld.goToArea(newY, newX);

      this.sprite.zIndex = this.y;
    }
  }

  get centerX(): number {
    return this.x + this.width / 2;
  }

  get centerY(): number {
    return this.y + this.height / 2;
  }

  destroy() {
    window.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('keydown', this.handleKeyDown);

    this.destroyed = true;
  }
}
