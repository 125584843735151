import World from './World';
import IEntity from './Entity.interface';
import Camera from './Camera';

class InstancesPool {
  entities: IEntity[] = [];
  p1: any;

  add(entity: IEntity): void {
    if (this.entityHasSprite(entity)) Camera.viewport.addChild(entity.sprite);
    if (this.entityHasArmSprite(entity))
      Camera.viewport.addChild(entity.armSprite);

    this.entities.push(entity);

    if (this.entityHasPhysics(entity))
      World.add(entity.ID, entity.x, entity.y, entity.width, entity.height);

    // console.debug(`Added entity "${entity.ID}" ("${entity.constructor.name}")`);

    if (entity.constructor?.name === 'Player') this.p1 = entity;
  }

  update(): void {
    // console.debug(`${this.entities.length} entities in the instances pool.`);

    // let updatedEntities: number = 0;
    // const startTime = Date.now();

    if (!!this.get(this.p1?.ID))
      Camera.moveTo({ x: this.get(this.p1.ID)!.x, y: this.get(this.p1.ID)!.y });

    this.entities.forEach((entity) => {
      if (entity.destroyed) this.remove(entity.ID);
      // Only update entities that are being displayed (a.k.a. culling).
      else if (
        entity.alwaysUpdate ||
        Camera.isInView({ x: entity.x, y: entity.y })
      ) {
        entity.update();
        // updatedEntities++;
      }
    });

    // console.debug(
    //   `Updated ${updatedEntities} entities in ${Date.now() - startTime}ms.`
    // );
  }

  remove(entityID: IEntity['ID']): void {
    if (this.entityHasSprite(this.get(entityID)!))
      Camera.viewport.removeChild(this.get(entityID)!.sprite);

    if (this.entityHasArmSprite(this.get(entityID)!))
      Camera.viewport.removeChild(this.get(entityID)!.armSprite);

    if (World.hasItem(entityID)) World.remove(entityID);

    if (!!this.get(entityID)) {
      // const entityType: string =
      //   this.get(entityID)?.constructor.name || 'UNKNOWN';

      this.entities = this.entities.filter((entity) => entity.ID !== entityID);

      // console.debug(`Removed entity "${entityID}" ("${entityType}")`);
    }
  }

  get(entityID: IEntity['ID']): IEntity | undefined {
    return this.entities.find((entity) => entity.ID === entityID);
  }

  entityHasPhysics(entity: IEntity): boolean {
    return !!(
      entity.ID &&
      entity.x !== undefined &&
      entity.y !== undefined &&
      entity.width &&
      entity.height &&
      entity.hasPhysics
    );
  }

  entityHasSprite(entity: IEntity): boolean {
    return !!entity?.sprite;
  }

  entityHasArmSprite(entity: IEntity): boolean {
    return !!entity?.armSprite;
  }
}

export default new InstancesPool();
