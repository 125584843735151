import DOT_DATA from './dot';
import PLAYER_WEAPON_SPARK_DATA from './player-weapon-spark';
import BLOOD_SPLAT_DATA from './blood-splat';
import HIT_DUST_DATA from './hit-dust';
import HIT_CIRCLE_DATA from './hit-circle';

export default Object.seal({
  dot: DOT_DATA,
  'player-weapon-spark': PLAYER_WEAPON_SPARK_DATA,
  'blood-splat': BLOOD_SPLAT_DATA,
  'hit-dust': HIT_DUST_DATA,
  'hit-circle': HIT_CIRCLE_DATA,
});
