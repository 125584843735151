import { cloneDeep, range } from 'lodash';
import { CellDirections } from '../config/constants';
import { pickRandomValue } from '../core/utils';
import CellTypes from '../data/world/cell-types';
import MapTypes from '../data/world/map-types';
import GameMap from '../entities/GameMap';
import RandomWalker from '../entities/RandomWalker';
import { CompositeTilemap } from '@pixi/tilemap';
import MapPopulator from '../core/MapPopulator';
import MapDecorator from './MapDecorator';
import MapDecorations from '../data/world/decorations';

export default class GameMapGenerator {
  private static corridorSize: number = 4;
  private static tileMap: number[][] = [[]];
  private static tiles = {
    floor: [5, 6, 7, 8, 9, 9, 9, 9, 9, 9, 9, 9, 9], //  TODO: Refactor using weighted table
    wall: [10, 11, 12, 13, 14],
    top: [1, 2, 3, 4],
  };

  public static generate(
    width: number,
    height: number,
    mapY: number,
    mapX: number,
    worldMap: any[][]
  ): GameMap {
    let structure: any[][] = [[]];

    for (const y of range(0, height)) {
      structure[y] = [];

      for (const x of range(0, width)) structure[y][x] = CellTypes.Wall;
    }

    // Initialize the random walker and carve floor cells
    structure = RandomWalker.carveFloors(structure);

    // Remove orphan wall cells
    structure = this.removeOrphanWalls(structure);
    structure = this.removeOrphanWalls(structure);

    // Carve exit corridors
    structure = this.carveExits(structure, mapX, mapY, worldMap);

    // Place floor/wall tiles
    const tileMap = this.mapTiles(structure);

    // Place exit point tiles
    const exitPoints = this.placeExitPoints(structure);

    // Place population
    const population = MapPopulator.populate(structure);

    // TODO: get frequencies and distances by biome instead of hardcoding them.
    // Place decorations
    const decorations = MapDecorator.decorate({
      structure,
      decorations: MapDecorations['Dirt'],
      floorDecorationsFrequency: 2,
      wallDecorationsFrequency: 2,
      floorDecorationsMinimumDistance: 8,
      wallDecorationsMinimumDistance: 8,
    });

    return new GameMap(
      structure,
      mapX,
      mapY,
      tileMap,
      population,
      decorations,
      exitPoints
    );
  }

  private static removeOrphanWalls(structure: any[][]): any[][] {
    const _structure = cloneDeep(structure);

    // Turn orphan wall cell into floor cell
    // TODO: ...1 or 0?
    for (const y of range(2, _structure.length - 1 - 1))
      for (const x of range(2, _structure[0].length - 1 - 1))
        if (this.getCellBitMask(_structure, x, y, CellTypes.Wall) === 1)
          _structure[y][x] = CellTypes.Floor;

    return _structure;
  }

  private static getCellBitMask(
    structure: number[][],
    x: number,
    y: number,
    cellType: CellTypes
  ): number {
    if (
      x < 0 ||
      y < 0 ||
      x > structure[0].length - 1 ||
      y > structure.length - 1
    )
      return 16;

    const northCell = structure[y - 1]?.[x] === cellType ? 1 : 0;
    const westCell = structure[y]?.[x - 1] === cellType ? 1 : 0;
    const eastCell = structure[y]?.[x + 1] === cellType ? 1 : 0;
    const southCell = structure[y + 1]?.[x] === cellType ? 1 : 0;

    // Get bitmask based on neighboring cells
    return (
      northCell * CellDirections.North +
      westCell * CellDirections.West +
      eastCell * CellDirections.East +
      southCell * CellDirections.South +
      1
    );
  }

  private static carveExits(
    structure: number[][],
    mapX: number,
    mapY: number,
    worldMap: any[][]
  ): any[][] {
    let _structure: number[][] = cloneDeep(structure);

    if (
      mapY < worldMap.length - 1 &&
      worldMap[mapY + 1][mapX] !== MapTypes.Void
    )
      _structure = this.carveExitSouth(_structure);

    if (mapY >= 1 && worldMap[mapY - 1][mapX] !== MapTypes.Void)
      _structure = this.carveExitNorth(_structure);

    if (
      mapX < worldMap[0].length - 1 &&
      worldMap[mapY][mapX + 1] !== MapTypes.Void
    )
      _structure = this.carveExitEast(_structure);

    if (mapX >= 1 && worldMap[mapY][mapX - 1] !== MapTypes.Void)
      _structure = this.carveExitWest(_structure);

    return _structure;
  }

  private static placeExitPoints(structure: any[][]) {
    const exitPoints = [];

    for (const y of range(0, structure.length - 1))
      for (const x of range(0, structure[y].length - 1))
        if (
          (x === 0 ||
            x > structure[y].length - 3 ||
            y === 0 ||
            y > structure.length - 3) &&
          structure[y][x] === CellTypes.Floor
        )
          exitPoints.push({ x, y });

    return exitPoints;
  }

  private static carveExitNorth(structure: number[][]) {
    const _structure = cloneDeep(structure);

    for (const y of range(0, _structure.length - 1))
      for (const x of range(0, _structure[0].length - 1))
        if (
          y >= 0 &&
          y <= _structure.length / 2 &&
          x <= _structure[0].length / 2 + this.corridorSize &&
          x >= _structure[0].length / 2 - this.corridorSize
        )
          _structure[y][x] = CellTypes.Floor;

    return _structure;
  }

  private static carveExitSouth(structure: number[][]) {
    const _structure = cloneDeep(structure);

    for (const y of range(0, _structure.length - 1))
      for (const x of range(0, _structure[0].length - 1))
        if (
          y <= _structure.length - 1 &&
          y >= _structure.length / 2 &&
          x <= _structure[0].length / 2 + this.corridorSize &&
          x >= _structure[0].length / 2 - this.corridorSize
        )
          _structure[y][x] = CellTypes.Floor;

    return _structure;
  }

  private static carveExitEast(structure: number[][]) {
    const _structure = cloneDeep(structure);

    for (const y of range(0, _structure.length - 1))
      for (const x of range(0, _structure[0].length - 1))
        if (
          y <= _structure.length / 2 + this.corridorSize &&
          y >= _structure.length / 2 - this.corridorSize &&
          x >= _structure[0].length / 2 &&
          x <= _structure[0].length - 1
        )
          _structure[y][x] = CellTypes.Floor;

    return _structure;
  }

  private static carveExitWest(structure: number[][]) {
    const _structure = cloneDeep(structure);

    for (const y of range(0, _structure.length - 1))
      for (const x of range(0, _structure[0].length - 1))
        if (
          y <= _structure.length / 2 + this.corridorSize &&
          y >= _structure.length / 2 - this.corridorSize &&
          x <= _structure[0].length / 2 &&
          x >= 0
        )
          _structure[y][x] = CellTypes.Floor;

    return _structure;
  }

  // Map tile indexes to cells
  private static mapTiles(structure: number[][]) {
    const tileMap: CellTypes[][] = [];

    for (const y of range(0, structure.length - 1)) {
      tileMap[y] = new Array(structure[y].length - 1);

      for (const x of range(0, structure[y].length - 1))
        if (structure[y][x] === CellTypes.Floor)
          // Pick a random floor tile
          tileMap[y][x] = this.pickTile(pickRandomValue(this.tiles.floor));
        else if (structure[y][x] === CellTypes.Wall)
          if (
            [1, 2, 3, 4, 5, 6, 7, 8].includes(
              this.getCellBitMask(structure, x, y, CellTypes.Wall)
            )
          )
            // If it's a wall cell that's confining with a floor cell
            // Pick a random wall tile
            tileMap[y][x] = this.pickTile(pickRandomValue(this.tiles.wall));
          // Otherwise pick a random wall top tile
          else tileMap[y][x] = this.pickTile(pickRandomValue(this.tiles.top));
    }

    return tileMap;
  }

  // TODO: Implement properly
  private static pickTile(n: number) {
    //   return love.graphics.newQuad(
    //     Math.ceil(n % 4) * CELL_SIZE,
    //     Math.floor(n / 4) * CELL_SIZE,
    //     CELL_SIZE,
    //     CELL_SIZE,
    //     BIOME_TILESET_WIDTH,
    //     BIOME_TILESET_HEIGHT
    // )

    return n;
  }
}
