import { Viewport } from 'pixi-viewport';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../config/constants';
import ICoordinates from './Coordinates.interface';
import app from './Game';
import { lerp, randomFloatInRange } from './utils';

class Camera {
  public viewport: Viewport;
  private speed: number = 0.04;
  public shakeAmount: number = 20;
  public shakeLength: number = 6;
  public shakeMagnitude: number = 10;
  private readonly zoomAmount: number = 2;

  constructor() {
    // Create the viewport
    this.viewport = new Viewport({
      screenWidth: SCREEN_WIDTH,
      screenHeight: SCREEN_HEIGHT,
      worldWidth: 1000,
      worldHeight: 1000,
      interaction: app.renderer.plugins.interaction, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
    });

    this.viewport.zoomPercent(this.zoomAmount);
    this.viewport.sortableChildren = true;
  }

  moveTo({ x, y }: ICoordinates): void {
    this.shakeAmount = Math.max(
      0,
      this.shakeAmount - (1 / this.shakeLength) * this.shakeMagnitude
    );

    this.viewport.moveCenter(
      lerp(
        this.viewport.center.x,
        x + randomFloatInRange(-this.shakeAmount, this.shakeAmount),
        this.speed
      ),
      lerp(
        this.viewport.center.y,
        y + randomFloatInRange(-this.shakeAmount, this.shakeAmount),
        this.speed
      )
    );
  }

  shake(amount: number): void {
    this.shakeAmount = amount;
  }

  /**
   * TODO: Add tolerance padding.
   * Checks if an object is being displayed.
   */
  isInView({ x, y }: ICoordinates): boolean {
    return (
      x >= this.viewport.left &&
      x <= this.viewport.left + SCREEN_WIDTH &&
      y >= this.viewport.top &&
      y <= this.viewport.top + SCREEN_HEIGHT
    );
  }
}

export default new Camera();
