import ICoordinates from '../core/Coordinates.interface';

export function lengthDirX(length: number, direction: number): Radians {
  return Math.cos(degToRad(direction)) * length;
}

export function lengthDirY(length: number, direction: number): Radians {
  return Math.sin(degToRad(direction)) * length;
}

export function directionTo(from: ICoordinates, to: ICoordinates): Degrees {
  return radtoDeg(Math.atan2(to.y - from.y, to.x - from.x));
}

export function degToRad(degrees: number): Radians {
  return degrees * (Math.PI / 180);
}

export function radtoDeg(radians: number): Degrees {
  return radians * (180 / Math.PI);
}

export function randomValueInRange(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function varyValue(value: number, variance: number): number {
  return value + randomValueInRange(-variance, variance);
}

type Degrees = number;
type Radians = number;
