import Handgun from './handgun';
import NeedleGun from './needle-gun';
import Shotgun from './shotgun';
import RailGun from './rail-gun';
import BaseballBat from './baseball-bat';

export default Object.seal({
  Handgun,
  NeedleGun,
  Shotgun,
  RailGun,
  BaseballBat,
}) as any;

export type PlayerWeaponType = {
  name: string;
  category: 'ranged' | 'melee';
  width: number;
  height: number;
  centerX: number;
  centerY: number;
  shotTimer: number;
  dispersion: number;
  sprite?: any;
  bulletSprite: string;
  particle: string;
  power: number;
  bulletSpeed: number;
  bulletFriction: number;
  bulletScale: number;
  shotSound?: any;
  bulletOffsetX: number;
  bulletOffsetY: number;
  kickBack: number;
  magazineSize: number;
  reloadTimer: number;
  magazineSprite?: any;
  // magazineSprite : love.graphics.newImage(
  //   'assets/sprites/player/weapons/magazines/magazine_needle_gun.png'
  // ),
  // reloadSound : love.audio.newSource(
  //   love.sound.newSoundData(
  //     'assets/sfx/player/weapons/reloads/reload_needle_gun.mp3'
  //   )
  // ),
  reloadSound?: any;
  bulletAmount: number;
  bulletSpread: number;
  bulletTimer?: number;
  bulletWidth: number;
  bulletHeight: number;
  knockBack: number;
  screenShake: number;
};
