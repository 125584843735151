import IEntity from '../core/Entity.interface';
import ParticlesManager from '../core/ParticlesManager';
import { CELL_SIZE, Directions } from '../config/constants';
import gfx from '../core/gfx';

export default class ExitPoint implements IEntity {
  ID: string =
    'ExitPoint_' + Math.ceil(Math.random() * Date.now()).toString(36);
  public destroyed: boolean = false;
  public hasPhysics: boolean = true;
  public width: number = CELL_SIZE;
  public height: number = CELL_SIZE;
  public x: number = 2;
  public y: number = 2;

  constructor(x: number, y: number, public readonly direction: Directions) {
    this.x = x;
    this.y = y;
  }

  public get position() {
    return { x: this.x, y: this.y };
  }

  update(): void {
    gfx.beginFill(0x00af42);
    gfx.drawCircle(this.x + CELL_SIZE / 2, this.y + CELL_SIZE / 2, 4);
  }

  destroy(): void {
    this.destroyed = true;
  }
}
