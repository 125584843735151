export const SCREEN_WIDTH: number = 1200;
export const SCREEN_HEIGHT: number = 800;
export const WORLD_SEED_WIDTH: number = 5;
export const WORLD_SEED_HEIGHT: number = 2;
export const WORLD_SEED_EXPANSION_SIZE: number = 3;
export enum CellDirections {
  North = 1,
  West = 2,
  East = 4,
  South = 8,
}
export const CELL_SIZE: number = 16;
export const COLLISION_GRID_SIZE: number = CELL_SIZE * 4;
export enum Directions {
  North = 1,
  West = 2,
  East = 3,
  South = 4,
}
