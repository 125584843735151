import ENEMY_WIZARD_STATE_DATA from './enemy-wizard';
import ENEMY_SLIME_STATE_DATA from './enemy-slime';

export default Object.seal({
  EnemyWizard: ENEMY_WIZARD_STATE_DATA,
  EnemySlime: ENEMY_SLIME_STATE_DATA,
});

export type EnemyStateID = keyof typeof ENEMY_WIZARD_STATE_DATA;
export type EnemyStatePropID = keyof typeof ENEMY_WIZARD_STATE_DATA.idle;
