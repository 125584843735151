import { range } from 'lodash';
import CellTypes from '../data/world/cell-types';
import ICoordinates from './Coordinates.interface';
import { getMatrixDistance, pickRandomValue } from './utils';

export type PopulationItem = {
  spawnID: string;
  x: number;
  y: number;
};

export default class MapPopulator {
  private static padding: number = 4;
  private static enemySpawnOdds: number = 1 / 5; // TODO: Make this a per-biome parameter.

  public static populate(
    structure: CellTypes[][]
  ): Map<string, PopulationItem[]> {
    const population: Map<string, PopulationItem[]> = new Map();

    population.set('enemies', this.placeEnemies(structure));
    population.set('npcs', this.placeNPCs(structure));

    return population;
  }

  private static placeEnemies(structure: CellTypes[][]): PopulationItem[] {
    const enemies = [];

    for (const y of range(this.padding, structure.length - this.padding - 1))
      for (const x of range(
        this.padding,
        structure[y].length - this.padding - 1
      ))
        if (
          structure[y][x] === CellTypes.Floor &&
          Math.random() > this.enemySpawnOdds &&
          !this.cellhasEnemiesNearby({
            cell: { x, y },
            enemies,
            minimumDistance: 24,
          }) &&
          !this.cellHasWallsNearby({
            cell: { x, y },
            structure,
          })
        )
          enemies.push({
            x,
            y,
            spawnID: pickRandomValue(['EnemySlime', 'EnemyWizard']), // TODO: Make this a per-biome parameter.
          });

    return enemies;
  }

  private static placeNPCs(structure: CellTypes[][]): PopulationItem[] {
    return [];
  }

  private static cellHasWallsNearby(opts: {
    cell: ICoordinates;
    structure: CellTypes[][];
  }): boolean {
    return (
      opts.structure[opts.cell.y - 1][opts.cell.x] === CellTypes.Wall ||
      opts.structure[opts.cell.y + 1][opts.cell.x] === CellTypes.Wall ||
      opts.structure[opts.cell.y][opts.cell.x - 1] === CellTypes.Wall ||
      opts.structure[opts.cell.y][opts.cell.x + 1] === CellTypes.Wall ||
      opts.structure[opts.cell.y - 1][opts.cell.x - 1] === CellTypes.Wall ||
      opts.structure[opts.cell.y - 1][opts.cell.x + 1] === CellTypes.Wall ||
      opts.structure[opts.cell.y + 1][opts.cell.x - 1] === CellTypes.Wall ||
      opts.structure[opts.cell.y + 1][opts.cell.x + 1] === CellTypes.Wall
    );
  }

  private static cellhasEnemiesNearby(opts: {
    cell: ICoordinates;
    enemies: PopulationItem[];
    minimumDistance: number;
  }): boolean {
    for (const enemy of opts.enemies)
      if (getMatrixDistance(opts.cell, enemy) <= opts.minimumDistance)
        return true;

    return false;
  }
}
