export default Object.seal({
  idle: {
    timer: 400,
    range: -1,
    speed: 0,
    bulletSpeed: 0,
    bulletFriction: 0,
    armX: 0,
    armY: 0,
    armAngle: 45,
  },
  roam: {
    timer: 800,
    range: -1,
    speed: 0.4,
    bulletSpeed: 0,
    bulletFriction: 0,
    armX: 0,
    armY: 0,
    armAngle: 40,
  },
  chase: {
    timer: 200,
    range: 150,
    speed: 1,
    bulletSpeed: 0,
    bulletFriction: 0,
    armX: 0,
    armY: 0,
    armAngle: 25,
  },
  attack1: {
    timer: 100,
    range: 125,
    speed: 0,
    bulletSpeed: 2,
    bulletFriction: 0,
    armX: 2,
    armY: -6,
    armAngle: 0,
  },
  dead: {
    timer: 100,
    range: 125,
    speed: 2,
    bulletSpeed: -1,
    bulletFriction: -1,
    armX: 2,
    armY: -6,
    armAngle: 0,
  },
});
