import graphics from '../core/gfx';
import IEntity from '../core/Entity.interface';
import InstancesPool from '../core/InstancesPool';
import World, { objectIsInLOS } from '../core/World';
import {
  calculateDistance,
  directionTo,
  lengthDirX,
  lengthDirY,
  lerp,
  pickRandomValue,
  varyValue,
} from '../core/utils';
import EnemyStateData, {
  EnemyStateID,
  EnemyStatePropID,
} from '../data/enemy-state';
import { FeatureFlag } from 'feature-flag-decorator';
import Bullet from './Bullet';
import AnimatedSpritesFactory from '../core/AnimatedSpritesFactory';
import { AnimatedSprite, BaseTexture, Sprite, Texture } from 'pixi.js';
import app from '../core/Game';
import ParticlesManager from '../core/ParticlesManager';
import Camera from '../core/Camera';

export default class EnemyWizard implements IEntity {
  ID: string =
    'EnemyWizard_' + Math.ceil(Math.random() * Date.now()).toString(36);
  public x: number;
  public y: number;
  width: number = 13;
  height: number = 19;
  vSpeed: number = 0;
  hSpeed: number = 0;
  private speed: number = 2;
  public sprite: AnimatedSprite;
  public sprites: { [spriteID: string]: Texture[] } = {};
  public armSprite: Sprite;
  private friction: number = 0.64;
  private acceleration: number = 0.64;
  private deceleration: number = 0.64;
  private direction: number = 0.64;
  // sprite: AnimatedSprite;
  public destroyed: boolean = false;
  public hasPhysics: boolean = true;
  public isSolid: boolean = true;
  public state: EnemyStateID = 'idle';
  private scale: number = 1;
  private facingDirection: number = 1;
  private angle: number = 0;
  private phase: number = 0;
  private timer: number = 0;
  private hp: number = 10;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;

    this.sprites = AnimatedSpritesFactory.generateAnimationsTexturesMap({
      Idle: {
        spriteSheet:
          app.loader.resources[
            'assets/sprites/enemies/enemy_wizard/enemy_wizard_idle.png'
          ].texture!.baseTexture,
        width: 13,
        height: 19,
        frames: 3,
      },
      Run: {
        spriteSheet:
          app.loader.resources[
            'assets/sprites/enemies/enemy_wizard/enemy_wizard_run.png'
          ].texture!.baseTexture,
        width: 13,
        height: 19,
        frames: 3,
      },
    });

    this.sprite = new AnimatedSprite(this.sprites.Idle);
    this.sprite.animationSpeed = 0.12;

    this.armSprite = new Sprite(
      app.loader.resources[
        'assets/sprites/enemies/enemy_wizard/enemy_wizard_arm.png'
      ].texture
    );
  }

  get position() {
    return { x: this.x, y: this.y };
  }

  update(): void {
    if (this.angle !== 0) this.angle = lerp(this.angle, 0, 0.1);
    if (this.scale !== this.facingDirection)
      this.scale = lerp(this.scale, this.facingDirection, 0.05);

    this.move();

    this.handleState();

    this.sprite.anchor.set(this.facingDirection === 1 ? 0 : 1, 0);

    this.draw();
  }

  private move(): void {
    if (this.state !== 'dead') {
      this.friction =
        this.getStateProp(this.state, 'speed') === 0
          ? this.deceleration
          : this.acceleration;

      this.hSpeed = lerp(
        this.hSpeed,
        lengthDirX(this.getStateProp(this.state, 'speed'), this.direction),
        this.friction
      );
      this.vSpeed = lerp(
        this.vSpeed,
        lengthDirY(this.getStateProp(this.state, 'speed'), this.direction),
        this.friction
      );
    } else this.hSpeed = lerp(this.hSpeed, 0, 0.04);

    const {
      x: newX,
      y: newY,
      collisions,
    } = World.move(
      this.ID,
      this.x + this.hSpeed,
      this.y + this.vSpeed,
      (_, otherID) =>
        InstancesPool.get(otherID)?.constructor?.name === 'Bullet'
          ? 'cross'
          : 'slide'
    );

    this.x = newX;
    this.y = newY;

    this.sprite.x = this.x;
    this.sprite.y = this.y;

    this.armSprite.anchor.set(0.6, 0.4);

    this.armSprite.x = lerp(
      this.armSprite.x,
      this.centerX +
        this.getStateProp(this.state, 'armX') * this.facingDirection,
      0.2
    );
    this.armSprite.y = lerp(
      this.armSprite.y,
      this.centerY + this.getStateProp(this.state, 'armY'),
      0.2
    );

    // this.armSprite.anchor.set(-6, 1);

    this.armSprite.angle = lerp(
      this.armSprite.angle,
      this.getStateProp(this.state, 'armAngle') * this.facingDirection,
      0.1
    );

    for (const collision of collisions) this.collide(collision.other);
  }

  private collide(otherID: string) {
    const other = InstancesPool.get(otherID);

    if (!other || this.state === 'dead') return;

    if (
      other.constructor?.name === 'Bullet' &&
      (other as any).owner !== 'enemy'
    ) {
      other.destroy();

      ParticlesManager.emit('hit-circle', {
        position: {
          x: (this.centerX + other.position.x) / 2,
          y: (this.centerY + other.position.y) / 2,
        },
        radius: 8,
      });

      ParticlesManager.emit('dot', {
        position: {
          x: (this.centerX + other.position.x) / 2,
          y: (this.centerY + other.position.y) / 2,
        },
        radius: 24,
      });

      if (this.hp-- <= 0) {
        this.hSpeed = Math.sign((other as Bullet).hSpeed) * 2;
        this.goToState('dead');
      }
    }
  }

  private handleState() {
    // @ts-ignore
    this[`${this.state.toLowerCase()}State`]();
  }

  jumpHeight: number = 0;
  jumping: boolean = false;

  private jump() {
    if (this.jumping) {
      this.jumpHeight = lerp(this.jumpHeight, 32, 0.45);
      if (this.jumpHeight >= 32 - 1) this.jumping = false;
    } else if (this.jumpHeight != 0)
      this.jumpHeight = lerp(this.jumpHeight, 0, 0.15);

    if (this.jumpHeight <= 0 + 1.9) {
      // Camera.shake(40);

      for (const _ of [1, 2, 3, 4])
        ParticlesManager.emit('hit-dust', {
          position: {
            x: this.centerX,
            y: this.centerY,
          },
          radius: 8,
        });

      this.destroy();
    }
  }

  private deadState(): void {
    if (this.phase === 0) {
      this.timer = this.getStateProp('idle', 'timer');
      this.phase++;
      this.jumping = true;
      this.friction = 0.9;
      Camera.shake(60);

      ParticlesManager.emit('hit-circle', {
        position: {
          x: this.centerX,
          y: this.centerY,
        },
        radius: 8,
      });
      ParticlesManager.emit('hit-circle', {
        position: {
          x: this.centerX,
          y: this.centerY,
        },
        radius: 8,
      });
      ParticlesManager.emit('hit-circle', {
        position: {
          x: this.centerX,
          y: this.centerY,
        },
        radius: 8,
      });
      ParticlesManager.emit('hit-circle', {
        position: {
          x: this.centerX,
          y: this.centerY,
        },
        radius: 8,
      });
      ParticlesManager.emit('hit-dust', {
        position: {
          x: this.centerX,
          y: this.centerY,
        },
        radius: 24,
      });

      return;
    }

    if (this.phase === 1) {
      this.timer--;

      this.jump();

      this.sprite.angle += 2;

      if (this.timer <= 0) this.destroy();
    }
  }

  private getStateProp(stateID: EnemyStateID, propID: EnemyStatePropID) {
    return EnemyStateData.EnemyWizard[stateID][propID];
  }

  private idleState(): void {
    if (this.phase === 0) {
      this.timer = this.getStateProp('idle', 'timer');
      this.speed = 0;
      this.phase++;

      return;
    }

    if (this.phase === 1) {
      this.timer--;

      if (this.timer <= 0) this.goToState(pickRandomValue(['idle', 'roam']));
      else if (
        objectIsInLOS(
          this.ID,
          InstancesPool.p1.ID,
          this.getStateProp('chase', 'range')
        )
      )
        this.goToState('chase');

      return;
    }
  }

  private roamState(): void {
    if (this.phase === 0) {
      this.timer = this.getStateProp('roam', 'timer');
      this.direction = Math.random() * 360;
      this.facingDirection = this.hSpeed >= 0 ? 1 : -1;
      this.phase++;

      return;
    }

    if (this.phase === 1) {
      this.timer--;

      this.facingDirection = this.hSpeed >= 0 ? 1 : -1;

      // if (this.timer % 70 === 0)
      // ParticlesManager.emit(
      // "dust",
      // 2,
      // this.centerX,
      // this.y + this.height,
      // this.facingDirection == 1 and 180 or 0
      // )

      if (this.timer <= 0) this.goToState(pickRandomValue(['idle', 'roam']));
      else if (
        objectIsInLOS(
          this.ID,
          InstancesPool.p1.ID,
          this.getStateProp('chase', 'range')
        )
      )
        // If player is close enough and line of sight is clear then start chasing
        this.goToState('chase');
    }
  }

  private chaseState(): void {
    if (this.phase === 0) {
      this.timer = varyValue(this.getStateProp('chase', 'timer'), 100);
      this.phase++;
    }

    if (this.phase === 1) {
      this.timer--;

      this.direction = directionTo(this.position, InstancesPool.p1.position);
      this.facingDirection = this.x <= InstancesPool.p1.x ? 1 : -1;

      // TODO: Emit particles...

      if (this.timer <= 0) {
        const distanceToP1 = calculateDistance(
          this.position,
          InstancesPool.p1.position
        );

        if (distanceToP1 >= this.getStateProp('chase', 'range'))
          this.goToState('idle');
        else if (distanceToP1 <= this.getStateProp('attack1', 'range'))
          this.goToState('attack1');
        else this.goToState('chase');
      }
    }
  }

  private attack1State(): void {
    if (this.phase === 0) {
      this.timer = varyValue(this.getStateProp('attack1', 'timer'), 10);
      this.phase++;
    }

    if (this.phase === 1) {
      this.timer--;
      // TODO: Emit particles...
      if (this.timer <= this.getStateProp('attack1', 'timer') / 5) this.phase++;
    }

    if (this.phase === 2) {
      this.timer--;

      const shotCoordinates = {
        x: this.centerX + this.facingDirection * 2,
        y: this.centerY - 8,
      };

      const shotDirection = directionTo(
        this.position,
        InstancesPool.p1.position
      );

      const shotAngle = 25;

      this.shoot(
        shotCoordinates.x,
        shotCoordinates.y,
        this.getStateProp(this.state, 'bulletSpeed'),
        this.getStateProp(this.state, 'bulletFriction'),
        shotDirection,
        'assets/sprites/enemies/bullets/enemy_bullet_1.png'
      );

      this.shoot(
        shotCoordinates.x,
        shotCoordinates.y,
        this.getStateProp(this.state, 'bulletSpeed'),
        this.getStateProp(this.state, 'bulletFriction'),
        shotDirection - shotAngle,
        'assets/sprites/enemies/bullets/enemy_bullet_1.png'
      );

      this.shoot(
        shotCoordinates.x,
        shotCoordinates.y,
        this.getStateProp(this.state, 'bulletSpeed'),
        this.getStateProp(this.state, 'bulletFriction'),
        shotDirection + shotAngle,
        'assets/sprites/enemies/bullets/enemy_bullet_1.png'
      );

      this.phase++;
    }

    if (this.phase === 3) if (this.timer-- <= 0) this.goToState('idle');
  }

  shoot(
    x: number,
    y: number,
    speed: number,
    friction: number,
    direction: number,
    sprite: string
  ): void {
    InstancesPool.add(
      new Bullet({
        x,
        y,
        speed,
        direction,
        friction,
        sprite,
        angledSprite: false,
        owner: 'enemy',
      })
    );
  }

  private goToState(stateID: EnemyStateID) {
    if (this.state !== stateID) {
      if (stateID === 'chase' || stateID === 'roam')
        this.sprite.textures = this.sprites['Run'];
      else this.sprite.textures = this.sprites['Idle'];

      this.sprite.play();
    }

    this.state = stateID;
    this.phase = 0;
  }

  destroy(): void {
    this.destroyed = true;
  }

  // @FeatureFlag('DEBUG_ENEMIES')
  draw(): void {
    graphics.beginFill(0x000000, 0.15);
    graphics.drawEllipse(
      this.centerX,
      this.centerY + 9,
      this.width * 0.6,
      this.height / 8
    );
    graphics.endFill();

    this.sprite.scale.x = this.facingDirection;
    this.sprite.play();
    this.sprite.y = this.y - this.jumpHeight;
    // this.drawDebug();
  }

  drawDebug(): void {
    graphics.beginFill(0xde3249, 0.5);
    graphics.drawRect(this.x, this.y, this.width, this.height);
    graphics.endFill();

    graphics.lineStyle(1, 0xffaa00); //(thickness, color)
    graphics.drawCircle(this.x, this.y, this.getStateProp('chase', 'range'));
  }

  get centerX(): number {
    return this.x + this.width / 2;
  }

  get centerY(): number {
    return this.y + this.height / 2;
  }
}
