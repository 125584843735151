import { CompositeTilemap } from '@pixi/tilemap';
import { range } from 'lodash';
import { Rectangle, Sprite, Texture } from 'pixi.js';
import { CellDirections } from '../config/constants';
import Camera from '../core/Camera';
import app from '../core/Game';
import gfx from '../core/gfx';
import MapTypes from '../data/world/map-types';
import GameWorld from '../entities/GameWorld';

export default class MiniMap {
  private static cellSize: number = 4;
  private static x: number = 0;
  private static y: number = 0;
  private static padding: number = 16;
  public static compositeTileMap: CompositeTilemap = new CompositeTilemap();
  private static tileTextures: Texture[] = app.loader.resources[
    'assets/minimap_tiles.png'
  ]
    ? range(0, 15).map(
        (i) =>
          new Texture(
            app.loader.resources[
              'assets/minimap_tiles.png'
            ].texture!.baseTexture,
            new Rectangle(i * 4, 0, 4, 4)
          )
      )
    : [];
  private static sprites: Sprite[][] = [
    [new Sprite(), new Sprite(), new Sprite()],
    [new Sprite(), new Sprite(), new Sprite()],
    [new Sprite(), new Sprite(), new Sprite()],
  ];
  private static __ = false;

  public static update() {
    this.x = Camera.viewport.left;
    this.y = Camera.viewport.bottom - this.cellSize * 8;

    if (!this.__)
      this.sprites.forEach((row) =>
        row.forEach((s) => Camera.viewport.addChild(s))
      );

    if (!this.tileTextures.length) {
      this.tileTextures = app.loader.resources['assets/minimap_tiles.png']
        ? range(0, 15).map(
            (i) =>
              new Texture(
                app.loader.resources[
                  'assets/minimap_tiles.png'
                ].texture!.baseTexture,
                new Rectangle(i * 4, 0, 4, 4)
              )
          )
        : [];
    }
    app.renderer.render(this.compositeTileMap);

    this.draw();
  }

  private static draw() {
    gfx.zIndex = 5000;

    if (this.tileTextures.length)
      for (const y of [
        GameWorld.currentAreaY - 1,
        GameWorld.currentAreaY,
        GameWorld.currentAreaY + 1,
      ])
        for (const x of [
          GameWorld.currentAreaX - 1,
          GameWorld.currentAreaX,
          GameWorld.currentAreaX + 1,
        ]) {
          if (GameWorld.structure[y]?.[x] !== MapTypes.Void) {
            const northCell =
              GameWorld.structure[y - 1]?.[x] !== MapTypes.Void ? 1 : 0;
            const westCell =
              GameWorld.structure[y]?.[x - 1] !== MapTypes.Void ? 1 : 0;
            const eastCell =
              GameWorld.structure[y]?.[x + 1] !== MapTypes.Void ? 1 : 0;
            const southCell =
              GameWorld.structure[y + 1]?.[x] !== MapTypes.Void ? 1 : 0;

            // Get bitmask based on neighboring cells
            const bitMask =
              northCell * CellDirections.North +
              westCell * CellDirections.West +
              eastCell * CellDirections.East +
              southCell * CellDirections.South;

            if (this.sprites[y]) this.sprites[y] = [];

            // if (!this.sprites[y]?.[x])
            //   this.sprites[y][x] = new Sprite(this.tileTextures[bitMask - 1]);
            // else this.sprites[y][x].texture = this.tileTextures[bitMask - 1];

            // this.sprites.forEach((row) =>
            //   row.forEach((s) => s.position.set(100, 100))
            // );

            //
            // console.debug(`bitmask: ${bitMask - 1}`);
            // this.compositeTileMap.tile(
            //   this.tileTextures[bitMask - 1],
            //   this.x +
            //     this.padding +
            //     (x - GameWorld.currentAreaX) * this.cellSize -
            //     this.cellSize / 4,
            //   this.y +
            //     this.padding +
            //     (y - GameWorld.currentAreaY) * this.cellSize -
            //     this.cellSize / 4,
            //   {
            //     tileWidth: 4,
            //     tileHeight: 4,
            //   }
            // );

            // gfx.beginFill(
            //   GameWorld.currentAreaX === x && GameWorld.currentAreaY === y
            //     ? 0xfafafa
            //     : 0xfaff1f,
            //   1
            // );

            // gfx.drawRect(
            //   this.x +
            //     this.padding +
            //     (x - GameWorld.currentAreaX) * this.cellSize -
            //     this.cellSize / 4,
            //   this.y +
            //     this.padding +
            //     (y - GameWorld.currentAreaY) * this.cellSize -
            //     this.cellSize / 4,
            //   this.cellSize,
            //   this.cellSize
            // );
          }
        }

    // Full map
    // for (const y of range(0, WORLD_SEED_HEIGHT * WORLD_SEED_EXPANSION_SIZE))
    //   for (const x of range(0, WORLD_SEED_WIDTH * WORLD_SEED_EXPANSION_SIZE))
    //     if (
    //       GameWorld.structure[y][x] !== MapTypes.Void &&
    //       true // GameWorld.structure[y][x].isExplored
    //     ) {
    //       gfx.beginFill(
    //         GameWorld.currentAreaX === x && GameWorld.currentAreaY === y
    //           ? 0xfafafa
    //           : 0xfaff1f,
    //         1
    //       );
    //       gfx.drawRect(
    //         this.x + this.padding + x * this.cellSize - this.cellSize / 4,
    //         this.y + this.padding + y * this.cellSize - this.cellSize / 4,
    //         this.cellSize / 2,
    //         this.cellSize / 2
    //       );
    //     }

    gfx.endFill();
  }
}
