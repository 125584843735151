enum CellTypes {
  // Wall = 'WALL',
  // Floor = 'FLOOR',
  // Void = 'VOID',
  Wall = 1,
  Floor = 2,
  Void = 0,
}

export default CellTypes;
