export default Object.seal({
  name: 'shotgun',
  category: 'ranged',
  width: 21,
  height: 8,
  centerX: 2,
  centerY: 1,
  shotTimer: 18,
  dispersion: 4,
  sprite: 'assets/sprites/player/weapons/shot_gun.png',
  bulletSprite: 'assets/sprites/player/bullets/hand_gun_bullet.png',
  particle: 'dust',
  power: 4,
  bulletSpeed: 15,
  bulletFriction: 1 - 0.1,
  bulletScale: 2,
  shotSound: 'assets/sounds/player/weapons/hand_gun/hand_gun_shot.wav',
  // bulletSprite : love.graphics.newImage(
  //   'assets/sprites/player/weapons/bullets/needle_bullet.png'
  // ),
  bulletOffsetX: 4,
  bulletOffsetY: 3,
  kickBack: 10,
  magazineSize: 2,
  reloadTimer: 100,
  // magazineSprite : love.graphics.newImage(
  //   'assets/sprites/player/weapons/magazines/magazine_needle_gun.png'
  // ),
  // reloadSound : love.audio.newSource(
  //   love.sound.newSoundData(
  //     'assets/sfx/player/weapons/reloads/reload_needle_gun.mp3'
  //   )
  // ),
  bulletAmount: 4,
  bulletSpread: 45,
  bulletTimer: 0.2,
  bulletWidth: 13,
  bulletHeight: 7,
  knockBack: 1,
  screenShake: 32,
});
