import Bump from 'bump-ts';
import { COLLISION_GRID_SIZE } from '../config/constants';
import InstancesPool from './InstancesPool';
import { calculateDistance } from './utils';

const world = Bump.newWorld(COLLISION_GRID_SIZE);

export default world;

export function objectIsInLOS(
  object1: string,
  object2: string,
  range: number
): boolean {
  const o1 = InstancesPool.get(object1);
  const o2 = InstancesPool.get(object2);

  if (!o1 || !o2) return false;

  if (calculateDistance(o1!.position, o2!.position) <= range) {
    const objectsInLOS = world.querySegment(o1.x, o1.y, o2.x, o2.y, () => true);

    if (
      !objectsInLOS.some(
        (obj) => InstancesPool.get(obj)?.constructor?.name === 'Wall'
      )
    )
      return true;
  }

  return false;
}
