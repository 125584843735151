import { range } from 'lodash';
import { getMatrixDistance, pickRandomValue } from '../core/utils';
import CellTypes from '../data/world/cell-types';
import { DecorationItem } from '../data/world/decorations';

export type DecorationSpawn = {
  decorationID: string;
  x: number;
  y: number;
  sprite: string;
  offsetX: number;
  offsetY: number;
  hp: number;
};

export default class MapDecorator {
  public static decorate(opts: {
    structure: CellTypes[][];
    decorations: {
      [decorationTypeID: string]: DecorationItem[];
    };
    floorDecorationsFrequency: number;
    wallDecorationsFrequency: number;
    floorDecorationsMinimumDistance: number;
    wallDecorationsMinimumDistance: number;
  }) {
    const decorations: Map<string, DecorationSpawn[]> = new Map();

    decorations.set(
      'floors',
      this.decorateFloors(
        opts.structure,
        opts.decorations.floors,
        opts.floorDecorationsFrequency,
        opts.floorDecorationsMinimumDistance
      )
    );

    decorations.set(
      'walls',
      this.decorateWalls(
        opts.structure,
        opts.decorations.walls,
        opts.wallDecorationsFrequency,
        opts.wallDecorationsMinimumDistance
      )
    );

    return decorations;
  }

  private static decorateWalls(
    structure: CellTypes[][],
    decorations: DecorationItem[],
    frequency: number,
    minimumDistance: number
  ) {
    const appliedWallDecorations = [];

    for (const y of range(1, structure.length - 1 - 1))
      for (const x of range(1, structure[y].length - 1 - 1))
        if (
          structure[y][x] === CellTypes.Wall &&
          structure[y][x + 1] !== CellTypes.Floor &&
          structure[y][x - 1] !== CellTypes.Floor &&
          structure[y + 1][x] !== CellTypes.Floor &&
          structure[y - 1][x] !== CellTypes.Floor &&
          Math.ceil(Math.random() * 10) <= frequency &&
          this.hasNoDecorationsNearby(
            { x, y },
            appliedWallDecorations,
            minimumDistance
          )
        ) {
          const decoration = pickRandomValue(decorations);

          appliedWallDecorations.push({
            x,
            y,
            sprite: decoration.sprite,
            offsetX: decoration.offsetX,
            offsetY: decoration.offsetY,
            hp: decoration.hp,
            decorationID: 'WallDecoration',
          });
        }

    return appliedWallDecorations;
  }

  private static decorateFloors(
    structure: CellTypes[][],
    decorations: DecorationItem[],
    frequency: number,
    minimumDistance: number
  ) {
    const appliedFloorDecorations = [];

    for (const y of range(0, structure.length - 1))
      for (const x of range(0, structure[y].length - 1))
        if (
          structure[y][x] === CellTypes.Floor &&
          structure[y - 1]?.[x] !== CellTypes.Wall &&
          structure[y + 1]?.[x] !== CellTypes.Wall &&
          structure[y][x - 1] !== CellTypes.Wall &&
          structure[y][x + 1] !== CellTypes.Wall &&
          Math.ceil(Math.random() * 10) <= frequency &&
          this.hasNoDecorationsNearby(
            { x, y },
            appliedFloorDecorations,
            minimumDistance
          )
        ) {
          const decoration = pickRandomValue(decorations);

          appliedFloorDecorations.push({
            x,
            y,
            sprite: decoration.sprite,
            offsetX: decoration.offsetX,
            offsetY: decoration.offsetY,
            hp: decoration.hp,
            decorationID: 'FloorDecoration',
          });
        }

    return appliedFloorDecorations;
  }

  private static hasNoDecorationsNearby(
    point: { x: number; y: number },
    decorations: any[],
    minimumDistance: number
  ): boolean {
    if (decorations?.length === 0) return true;

    for (const decoration of decorations)
      if (getMatrixDistance(point, decoration) <= minimumDistance) return false;

    return true;
  }
}
