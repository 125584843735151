import { Sprite } from 'pixi.js';
import Game from '../core/Game';
import IEntity from '../core/Entity.interface';
import { lengthDirX, lengthDirY } from '../core/utils';
import World from '../core/World';
import InstancesPool from '../core/InstancesPool';
import Camera from '../core/Camera';
import ParticlesManager from '../core/ParticlesManager';
import Gfx from '../core/gfx';

export default class Bullet implements IEntity {
  ID: string = 'Bullet_' + Math.ceil(Math.random() * Date.now()).toString(36);
  x: number;
  y: number;
  width: number = 4;
  height: number = 4;
  vSpeed: number = 0;
  hSpeed: number = 0;
  sprite: Sprite;
  private speed: number = 2;
  private friction: number = 0;
  private direction: number = 0;
  public destroyed: boolean = false;
  public hasPhysics: boolean = true;
  public owner: 'player' | 'enemy';

  constructor(opts: {
    x: number;
    y: number;
    speed: number;
    direction: number;
    friction: number;
    sprite: string;
    angledSprite?: boolean;
    owner: 'player' | 'enemy';
  }) {
    this.x = opts.x;
    this.y = opts.y;
    this.speed = opts.speed;
    this.direction = opts.direction;
    this.friction = opts.friction;
    this.owner = opts.owner;

    this.sprite = new Sprite(Game.loader.resources[opts.sprite].texture);
    this.width = this.sprite.width;
    this.height = this.sprite.height;

    this.hSpeed = lengthDirX(this.speed, this.direction);
    this.vSpeed = lengthDirY(this.speed, this.direction);

    this.sprite.anchor.set(0.5);
    this.sprite.angle = opts.angledSprite ? this.direction : 0;
  }

  get position() {
    return {
      x: this.x,
      y: this.y,
    };
  }

  update(): void {
    if (this.destroyed) return;

    this.move();

    this.draw();
  }

  move(): void {
    const {
      x: newX,
      y: newY,
      collisions,
    } = World.move(
      this.ID,
      this.x + this.hSpeed,
      this.y + this.vSpeed,
      (_itemID: IEntity['ID'], otherID: IEntity['ID']) => {
        if (InstancesPool.get(otherID)?.constructor?.name === 'Wall')
          return 'touch';

        return 'cross';
      }
    );

    for (const collision of collisions) this.handleCollision(collision);

    this.x = newX;
    this.y = newY;

    this.sprite.x = this.x;
    this.sprite.y = this.y;

    if (this.friction) {
      this.hSpeed *= this.friction;
      this.vSpeed *= this.friction;
    }

    if (!Camera.isInView(this.position)) this.destroy();

    if (
      this.friction !== 0 &&
      Math.abs(this.hSpeed) + Math.abs(this.vSpeed) < 4
    ) {
      ParticlesManager.emit('hit-dust', {
        position: this.position,
        radius: (this.width + this.height) / 2,
      });

      this.destroy();
    }
  }

  handleCollision(collision: any): void {
    if (InstancesPool.get(collision.other)?.constructor?.name === 'Wall') {
      ParticlesManager.emit('dot', {
        position: this.position,
        radius: (this.width + this.height) / 2,
      });

      ParticlesManager.emit('hit-dust', {
        position: this.position,
        radius: (this.width + this.height) / 2,
      });

      this.destroy();
    }

    if (
      InstancesPool.get(collision.other)?.constructor?.name ===
      'FloorDecoration'
    )
      InstancesPool.get(collision.other)!.destroy();
  }

  draw(): void {
    this.sprite.roundPixels = true;
    this.sprite.zIndex = this.y + 100;
    // Gfx.beginFill(0xfafa00, 1);
    // Gfx.drawRect(this.x, this.y, this.width, this.height);
    // Gfx.endFill();

    // ParticlesManager.emit('dot', { position: this.position, radius: 4 });

    this.drawShadow();
  }

  drawShadow(): void {
    Gfx.beginFill(0x000000, 0.1);
    Gfx.drawEllipse(this.x, this.y + this.height + 6, this.width / 2, 2);
    Gfx.endFill();
  }

  destroy(): void {
    this.destroyed = true;
  }
}
