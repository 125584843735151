import { Rectangle, Texture } from 'pixi.js';
import { CompositeTilemap } from '@pixi/tilemap';
import { range } from 'lodash';
import { CELL_SIZE, Directions } from '../config/constants';
import Camera from '../core/Camera';
import IEntity from '../core/Entity.interface';
import app from '../core/Game';
import InstancesPool from '../core/InstancesPool';
import { PopulationItem } from '../core/MapPopulator';
import CellTypes from '../data/world/cell-types';
import { DecorationSpawn } from '../procgen/MapDecorator';
import DecorationSpawner from './DecorationSpawner';
import Spawner from './Spawner';
import Wall from './Wall';
import ExitPoint from './ExitPoint';
import LightRay from './LighRay';
import GameWorld from './GameWorld';
import { randomValueInRange } from '../core/utils';

export default class GameMap {
  ID: string = 'Area_' + Math.ceil(Math.random() * Date.now()).toString(36);
  public structure: any[][] = [[]];
  public mapX: number = 0;
  public mapY: number = 0;
  public biome: string = 'DIRT';
  public isExplored: boolean = false;
  private objectsIDs: IEntity['ID'][] = [];
  public compositeTileMap: CompositeTilemap = new CompositeTilemap();

  constructor(
    structure: any[][],
    mapX: number,
    mapY: number,
    public tileMap: number[][],
    public population: Map<string, PopulationItem[]>,
    public decorations: Map<string, DecorationSpawn[]>,
    public exitPoints: { x: number; y: number }[]
  ) {
    this.structure = structure;
    this.mapX = mapX;
    this.mapY = mapY;
  }

  public spawnOverlays() {
    InstancesPool.add(new LightRay(0, 0, 0, 0));

    if (
      GameWorld.currentAreaX === this.mapX &&
      GameWorld.currentAreaY === this.mapY
    ) {
      setTimeout(() => {
        this.spawnOverlays();
      }, randomValueInRange(600, 900));
    }
  }

  removeOwnObjects(): void {
    let removedObjects: number = 0;

    for (const objectID of this.objectsIDs) {
      InstancesPool.get(objectID)?.destroy();
      InstancesPool.remove(objectID);
      this.objectsIDs = this.objectsIDs.filter((oID) => oID !== objectID);
      removedObjects++;
    }

    console.debug(
      `Removed ${removedObjects} objects from map "${this.ID}" (y: ${this.mapY}, x:${this.mapX}).`
    );
  }

  public spawnExitPoints(): void {
    let placedExitPoints: number = 0;

    for (const exitPoint of this.exitPoints) {
      const _exitPoint = new ExitPoint(
        exitPoint.x * CELL_SIZE,
        exitPoint.y * CELL_SIZE,
        exitPoint.y === 0
          ? Directions.North
          : exitPoint.y >= this.structure.length - 2
          ? Directions.South
          : exitPoint.x === 0
          ? Directions.West
          : Directions.East
      );

      InstancesPool.add(_exitPoint);

      this.registerObject(_exitPoint);

      placedExitPoints++;
    }

    console.debug(
      `Spawned ${placedExitPoints} exit points for map "${this.ID}".`
    );
  }

  public spawnWalls(): void {
    let placedWalls: number = 0;

    for (const y of range(0, this.structure.length))
      for (const x of range(0, this.structure[0].length))
        if (this.structure[y][x] === CellTypes.Wall) {
          const wall = new Wall(
            x * CELL_SIZE,
            y * CELL_SIZE,
            CELL_SIZE,
            CELL_SIZE
          );
          InstancesPool.add(wall);
          this.registerObject(wall);
          placedWalls++;
        }

    console.debug(`Spawned ${placedWalls} wall objects for map "${this.ID}".`);
  }

  public spawnPopulation(): void {
    let placedEnemies: number = 0;

    for (const toSpawn of this.population.get('enemies') || []) {
      const spawner = new Spawner(
        toSpawn.x * CELL_SIZE,
        toSpawn.y * CELL_SIZE,
        toSpawn.spawnID,
        this
      );
      InstancesPool.add(spawner);
      this.registerObject(spawner);
      placedEnemies++;
    }

    console.debug(`Spawned ${placedEnemies} entities for map "${this.ID}".`);
  }

  public spawnDecorations(): void {
    let placedDecorations: number = 0;

    for (const toSpawn of this.decorations.get('walls') || []) {
      const spawner = new DecorationSpawner(
        toSpawn.x * CELL_SIZE,
        toSpawn.y * CELL_SIZE,
        toSpawn.decorationID,
        toSpawn.sprite,
        toSpawn.offsetX,
        toSpawn.offsetY,
        this
      );
      InstancesPool.add(spawner);
      this.registerObject(spawner);
      placedDecorations++;
    }

    for (const toSpawn of this.decorations.get('floors') || []) {
      const spawner = new DecorationSpawner(
        toSpawn.x * CELL_SIZE,
        toSpawn.y * CELL_SIZE,
        toSpawn.decorationID,
        toSpawn.sprite,
        toSpawn.offsetX,
        toSpawn.offsetY,
        this
      );
      InstancesPool.add(spawner);
      this.registerObject(spawner);
      placedDecorations++;
    }

    console.debug(
      `Spawned ${placedDecorations} decorations for map "${this.ID}".`
    );
  }

  // Not private because Spawner uses it too.
  public registerObject(entity: IEntity) {
    this.objectsIDs.push(entity.ID);
  }

  public registerTiles(): void {
    for (const y of range(0, this.structure.length - 1))
      for (const x of range(0, this.structure[y].length - 1)) {
        const tileIndex = this.tileMap[y][x];
        const tileOffsetX = tileIndex % 4;
        const tileOffsetY = Math.floor(tileIndex / 4);

        const tileTexture = new Texture(
          app.loader.resources['assets/tileset_dirt_alt.png'].texture!.baseTexture,
          new Rectangle(
            tileOffsetX * CELL_SIZE,
            tileOffsetY * CELL_SIZE,
            CELL_SIZE,
            CELL_SIZE
          )
        );

        this.compositeTileMap.tile(tileTexture, x * CELL_SIZE, y * CELL_SIZE, {
          tileWidth: CELL_SIZE,
          tileHeight: CELL_SIZE,
        });
      }

    for (const y of range(0, this.structure.length - 1))
      for (const x of range(0, this.structure[y].length - 1))
        if (
          x === 0 ||
          x === this.structure[y].length - 1 - 1 ||
          y === 0 ||
          y === this.structure.length - 1 - 1
        ) {
          const tileOffsetX =
            x === 0 ? 0 : x === this.structure[y].length - 1 - 1 ? 2 : 1;
          const tileOffsetY =
            y === 0 ? 0 : y === this.structure.length - 1 - 1 ? 2 : 1;

          const borderTileTexture = new Texture(
            app.loader.resources[
              'assets/sprites/map_border.png'
            ].texture!.baseTexture,
            new Rectangle(
              tileOffsetX * CELL_SIZE,
              tileOffsetY * CELL_SIZE,
              CELL_SIZE,
              CELL_SIZE
            )
          );

          this.compositeTileMap.tile(
            borderTileTexture,
            x * CELL_SIZE,
            y * CELL_SIZE,
            {
              tileWidth: CELL_SIZE,
              tileHeight: CELL_SIZE,
            }
          );
        }

    Camera.viewport.addChild(this.compositeTileMap);

    console.debug(
      `Registered ${this.compositeTileMap.children.length} tiles for the current map.`
    );
  }

  public unregisterTiles() {
    this.compositeTileMap.removeChildren();
  }

  public setExplored(): void {
    this.isExplored = true;
  }
}
