import { cloneDeep, random, range } from 'lodash';
import { lengthDirX, lengthDirY } from '../core/utils';
import CellTypes from '../data/world/cell-types';
import ICoordinates from '../core/Coordinates.interface';

export default class RandomWalker {
  private static padding: number = 4;
  private static directionChangeOdds: number = 2;
  private static xDirection: number = 0;
  private static yDirection: number = 0;
  private static direction: number = 0;
  private static position: ICoordinates = { x: 0, y: 0 };
  private static carveHoleOdds: number = 1 / 4;

  public static carveFloors(structure: number[][]) {
    // Initialize position at the middle of the given structure
    this.position = {
      x: Math.ceil(structure[0].length / 2),
      y: Math.ceil(structure.length / 2),
    };

    let _structure = cloneDeep(structure);

    for (const _step of range(0, structure.length * 42)) {
      _structure[this.position.y][this.position.x] = CellTypes.Floor;

      // Randomly carve a larger hole
      if (Math.random() > this.carveHoleOdds) {
        // _structure = this.carveHole(
        //   _structure,
        //   this.position.x,
        //   this.position.y
        // );

        if (this.position.y > this.padding + 1)
          _structure[this.position.y - 1][this.position.x] = CellTypes.Floor;

        if (this.position.x < _structure[0].length + 1 - this.padding)
          _structure[this.position.y][this.position.x + 1] = CellTypes.Floor;

        if (this.position.x > this.padding + 1)
          _structure[this.position.y][this.position.x - 1] = CellTypes.Floor;

        if (this.position.y < _structure.length + 1 - this.padding)
          _structure[this.position.y + 1][this.position.x] = CellTypes.Floor;
      }

      this.move(0 + 1, 0 + 1, structure[0].length - 1, structure.length - 1);
    }

    return _structure;
  }

  private static carveHole(
    structure: number[][],
    x: number,
    y: number
  ): number[][] {
    const _structure = cloneDeep(structure);

    if (y > this.padding + 1) _structure[y - 1][x] = CellTypes.Floor;

    if (x < _structure[0].length + 1 - this.padding)
      _structure[y][x + 1] = CellTypes.Floor;

    if (x > this.padding + 1) _structure[y][x - 1] = CellTypes.Floor;

    if (y < _structure.length + 1 - this.padding)
      _structure[y + 1][x] = CellTypes.Floor;

    return _structure;
  }

  private static move(
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ): void {
    if (
      Math.ceil(Math.random() * this.directionChangeOdds) ===
      this.directionChangeOdds
    )
      this.direction = random(0, 3);

    this.xDirection = lengthDirX(1, this.direction * 90);
    this.yDirection = lengthDirY(1, this.direction * 90);

    this.position.x += this.xDirection;
    this.position.y += this.yDirection;

    if (this.isOutOfBoundsX(startX, endX))
      this.position.x -= this.xDirection * 2;

    if (this.isOutOfBoundsY(startY, endY))
      this.position.y -= this.yDirection * 2;
  }

  private static isOutOfBoundsX(startX: number, endX: number): boolean {
    return (
      this.position.x < startX + this.padding ||
      this.position.x > endX - this.padding
    );
  }

  private static isOutOfBoundsY(startY: number, endY: number): boolean {
    return (
      this.position.y < startY + this.padding ||
      this.position.y > endY - this.padding
    );
  }
}
