import { Sprite } from 'pixi.js';
import IEntity from '../core/Entity.interface';
import app from '../core/Game';
import gfx from '../core/gfx';
import InstancesPool from '../core/InstancesPool';
import { calculateDistance, remapValue } from '../core/utils';
import FloatingDebris from './FloatingDebris';

export default class FloorDecoration implements IEntity {
  public readonly ID: string =
    'FloorDecoration_' + Math.ceil(Math.random() * Date.now()).toString(36);
  public width: number = 2;
  public height: number = 2;
  public hasPhysics: boolean = true;
  public isSolid: boolean = false;
  public destroyed: boolean = false;
  public sprite: Sprite;

  constructor(
    public x: number,
    public y: number,
    spritePath: string,
    offsetX: number,
    offsetY: number
  ) {
    this.sprite = new Sprite(app.loader.resources[spritePath].texture);
    this.sprite.x = this.x - offsetX;
    this.sprite.y = this.y - offsetY;
    this.width = this.sprite.width;
    this.height = this.sprite.height;
  }

  get position() {
    return { x: this.x, y: this.y };
  }

  update() {
    this.sprite.alpha = remapValue(
      calculateDistance(this.position, InstancesPool.p1.position),
      0,
      100,
      0.4,
      1
    );

    this.sprite.zIndex = this.y - 8;

    // this.drawDebug();
  }

  drawDebug() {
    gfx.beginFill(0xfafa00, 1);
    gfx.drawRect(this.x, this.y, this.width, this.height);
    gfx.endFill();
  }

  destroy(): void {
    InstancesPool.add(new FloatingDebris(this.x, this.y));
    InstancesPool.add(new FloatingDebris(this.x, this.y));
    InstancesPool.add(new FloatingDebris(this.x, this.y));
    InstancesPool.add(new FloatingDebris(this.x, this.y));

    this.destroyed = true;
  }
}
