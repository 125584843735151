import Camera from '../core/Camera';
import gfx from '../core/gfx';
import InstancesPool from '../core/InstancesPool';
import EnemySlime from './EnemySlime';
import EnemyWizard from './EnemyWizard';
import GameMap from './GameMap';

export default class Spawner {
  ID: string = 'Spawner_' + Math.ceil(Math.random() * Date.now()).toString(36);
  public destroyed: boolean = false;
  private spawnTime: number = Date.now() + 4 * 1000;
  public width: number = 10;
  public height: number = 10;
  public hasPhysics: boolean = false;

  constructor(
    public readonly x: number,
    public readonly y: number,
    private readonly spawnID: string,
    private readonly parentMap: GameMap
  ) {}

  get position() {
    return { x: this.x, y: this.y };
  }

  update(): void {
    if (Date.now() >= this.spawnTime) {
      this.spawnEntity();
      this.destroy();
    }

    this.drawDebug();
  }

  drawDebug(): void {
    gfx.beginFill(0xfafa00, 1);
    gfx.drawRect(this.x, this.y, this.width, this.height);
    gfx.endFill();
  }

  // TODO: Emit particles on spawn
  spawnEntity(): void {
    Camera.shake(20);

    let spawnedEntity;

    switch (this.spawnID) {
      case 'EnemyWizard': {
        spawnedEntity = new EnemyWizard(this.x, this.y);
        break;
      }
      case 'EnemySlime': {
        spawnedEntity = new EnemySlime(this.x, this.y);
        break;
      }
    }

    if (spawnedEntity) {
      InstancesPool.add(spawnedEntity);
      this.parentMap.registerObject(spawnedEntity);
    }
  }

  destroy(): void {
    this.destroyed = true;
  }
}
