export default {
  behaviors: [
    {
      type: 'alpha',
      config: {
        alpha: {
          list: [
            {
              value: 1,
              time: 0,
            },
            {
              value: 0.8,
              time: 1,
            },
          ],
          isStepped: false,
        },
      },
    },
    {
      type: 'scale',
      config: {
        scale: {
          list: [
            {
              value: 1,
              time: 0,
            },
            {
              value: 0.9,
              time: 1,
            },
          ],
          isStepped: false,
        },
      },
    },
    {
      type: 'color',
      config: {
        color: {
          list: [
            {
              value: 'ffffff',
              time: 0,
            },
            {
              value: 'fafafa',
              time: 1,
            },
          ],
          isStepped: false,
        },
      },
    },
    {
      type: 'moveSpeed',
      config: {
        speed: {
          list: [
            {
              value: 16,
              time: 0,
            },
            {
              value: 2,
              time: 1,
            },
          ],
          isStepped: false,
        },
      },
    },
    {
      type: 'rotationStatic',
      config: {
        min: 0,
        max: 360,
      },
    },
    {
      type: 'spawnShape',
      config: {
        type: 'circle',
        data: {
          x: 200,
          y: 200,
          radius: 2,
        },
      },
    },
  ],
  rotationSpeed: {
    min: 0,
    max: 0,
  },
  lifetime: {
    min: 0.1,
    max: 0.1,
  },
  frequency: 0.01,
  spawnChance: 1,
  particlesPerWave: 1,
  emitterLifetime: 0.02,
  maxParticles: 40,
  pos: {
    x: 200,
    y: 200,
  },
  addAtBack: false,
};
