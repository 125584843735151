import { Sprite } from 'pixi.js';
import IEntity from '../core/Entity.interface';
import Game from '../core/Game';
import gfx from '../core/gfx';
import {
  degToRad,
  lengthDirX,
  lengthDirY,
  lerp,
  randomValueInRange,
} from '../core/utils';

export default class FloatingDebris implements IEntity {
  ID: string =
    'FloatingDebris_' + Math.ceil(Math.random() * Date.now()).toString(36);
  width: number = 4;
  height: number = 4;
  vSpeed: number = 0;
  hSpeed: number = 0;
  sprite: Sprite;
  private speed: number = 2;
  private friction: number = 0.2;
  private direction: number = 0;
  public destroyed: boolean = false;
  public hasPhysics: boolean = false;
  private floatingProgress: number = 0;
  private jumpHeight: number = 0.11;
  private wobbleAmount: number = 0;

  constructor(public x: number, public y: number) {
    this.x = randomValueInRange(x - 8, x + 8);
    this.y = randomValueInRange(y - 8, y + 8);
    this.speed = randomValueInRange(1, 2);
    this.direction = randomValueInRange(0, 359);

    this.sprite = new Sprite(
      Game.loader.resources[
        'assets/sprites/decorations/spr_decoration_dirt_floor_debris_1.png'
      ].texture
    );
  }

  get position() {
    return {
      x: this.x,
      y: this.y,
    };
  }

  update(): void {
    if (this.destroyed) return;

    this.move();

    this.draw();
  }

  move() {
    this.hSpeed = lengthDirX(this.speed, this.direction);
    this.vSpeed = lengthDirY(this.speed, this.direction);

    this.x += this.hSpeed;
    this.y += this.vSpeed;

    this.speed = lerp(this.speed, 0, Math.random());

    if (this.floatingProgress < 180)
      this.floatingProgress = lerp(
        this.floatingProgress,
        180,
        Math.random() / 10
      );

    this.jumpHeight = Math.sin(degToRad(this.floatingProgress)) * 20;
    this.wobbleAmount = Math.sin(degToRad(this.floatingProgress * 4)) * 2;

    if (this.jumpHeight <= 0.1) {
      this.sprite.alpha -= 0.01;

      if (this.sprite.alpha <= 0.1) this.destroy();
    }
  }

  draw() {
    gfx.beginFill(0x000000, 0.1);
    gfx.zIndex = this.sprite.y - 32;
    gfx.drawEllipse(
      this.x + 2,
      this.y + 2,
      2 + 2 * (this.jumpHeight / 20),
      1 + 1 * (this.jumpHeight / 20)
    );
    gfx.endFill();

    this.sprite.x = this.x + this.wobbleAmount;
    this.sprite.y = this.y - this.jumpHeight;

    this.sprite.zIndex = this.sprite.y - 8;
  }

  destroy() {
    this.destroyed = true;
  }
}
