import * as PIXI from 'pixi.js';
import PlayerWeaponData, { PlayerWeaponType } from '../data/player-weapons';
import { range } from 'lodash';
import Gfx from '../core/gfx';
import {
  directionTo,
  lengthDirX,
  lengthDirY,
  lerp,
  radToDeg,
} from '../core/utils';
import app from '../core/Game';
import IEntity from '../core/Entity.interface';
import InstancesPool from '../core/InstancesPool';
import Player from './Player';
import Bullet from './Bullet';
import { varyValue } from '../lib/utils';
import Camera from '../core/Camera';
import ParticlesManager from '../core/ParticlesManager';
import { sound } from '@pixi/sound';
import InstantBullet from './InstantBullet';

export default class PlayerWeapon implements IEntity {
  ID: string = Math.ceil(Math.random() * Date.now()).toString(36);
  ownerID: string;
  weaponID: keyof typeof PlayerWeaponData = 'Handgun';
  x: number = 0;
  y: number = 0;
  width: number = 8;
  height: number = 16;
  sprite: PIXI.Sprite;
  kickBack: number = 0;
  direction: number = 0;
  scale: number = 1;
  angle: number = 0;
  swing: number = 1;
  shotTimer: number = 0;
  reloadTimer: number = 0;
  public destroyed: boolean = false;
  private inputMask = {
    click: false,
  };
  magazine: number = 2;
  hasPhysics: boolean = false;

  constructor(ownerID: string) {
    this.ownerID = ownerID;

    window.addEventListener('mousedown', this.handleMouseDown.bind(this));
    window.addEventListener('mouseup', this.handleMouseUp.bind(this));
    window.addEventListener('mousewheel', this.handleMouseWheel.bind(this));

    this.sprite = new PIXI.Sprite(
      app.loader.resources[this.getProp('sprite')].texture
    );

    sound.add('shot-sound', {
      url: 'assets/sounds/player/weapons/hand_gun/hand_gun_shot.wav',
      volume: 0.05,
    });
  }

  private switchWeapon(indexModifier: number): void {
    if (indexModifier === 0) return;

    const currentWeaponIndex: number = Object.keys(PlayerWeaponData).findIndex(
      (weaponID) => weaponID === this.weaponID
    );

    const nextWeaponIndex: number =
      currentWeaponIndex + indexModifier >
      Object.keys(PlayerWeaponData).length - 1
        ? 0
        : currentWeaponIndex + indexModifier < 0
        ? Object.keys(PlayerWeaponData).length - 1
        : currentWeaponIndex + indexModifier;

    this.weaponID = Object.keys(PlayerWeaponData)[nextWeaponIndex];

    this.magazine = this.getProp('magazineSize');
    this.sprite.texture = new PIXI.Sprite(
      app.loader.resources[this.getProp('sprite')].texture
    ).texture;

    sound.remove('shot-sound');

    sound.add('shot-sound', {
      url: this.getProp('shotSound'),
      volume: 0.05,
    });

    this.scale = 0;

    this.sprite.pivot.set(this.getProp('centerX'), this.getProp('centerY'));
  }

  private handleMouseWheel(event: Event): void {
    this.switchWeapon(Math.sign((event as any).wheelDeltaY));
  }

  handleMouseDown(event: MouseEvent): void {
    this.inputMask.click = true;
  }

  handleMouseUp(event: MouseEvent): void {
    this.inputMask.click = false;
  }

  getProp(propID: keyof PlayerWeaponType) {
    return PlayerWeaponData[this.weaponID][propID];
  }

  move(): void {
    this.x = lerp(
      this.x,
      ((InstancesPool.get(this.ownerID) as unknown as Player)?.centerX || 0) -
        this.getProp('centerX') * this.facingDirection,
      0.32
    );
    this.y = lerp(
      this.y,
      ((InstancesPool.get(this.ownerID) as unknown as Player)?.centerY || 0) -
        this.getProp('centerY'),
      0.48
    );
  }

  update(): void {
    this.move();

    if (this.kickBack !== 0) this.kickBack = lerp(this.kickBack, 0, 0.2);
    if (this.scale !== 1) this.scale = lerp(this.scale, 1, 0.2);

    const nextDirection =
      directionTo(
        Camera.viewport.toGlobal({ x: this.x, y: this.y }),
        app.renderer.plugins.interaction.mouse.global
      ) + (this.getProp('category') === 'melee' ? this.angle : 0);

    this.direction =
      this.getProp('category') === 'ranged' ? nextDirection : nextDirection; //lerp(this.direction, nextDirection, 0.2);

    if (this.getProp('category') === 'melee')
      this.angle = lerp(
        this.angle,
        radToDeg(-this.facingDirection * this.swing * 3),
        0.24
      );
    else if (this.angle !== 0) this.angle = 0;

    this.handleShooting();

    this.draw();
  }

  handleShooting(): void {
    if (this.inputMask.click)
      if (this.shotTimer === 0 && this.reloadTimer === 0)
        if (this.getProp('category') === 'ranged') this.shoot();
        else this.swiing();

    if (this.shotTimer > 0) this.shotTimer--;
    if (this.reloadTimer > 0) this.reloadTimer--;
  }

  swiing(): void {
    this.scale = 1.6;
    this.swing = -this.swing;
    this.shotTimer = this.getProp('shotTimer');
  }

  shoot(): void {
    sound.play('shot-sound');

    if (this.magazine > 1) {
      this.magazine--;
      this.shotTimer = this.getProp('shotTimer');
    } else {
      this.magazine = this.getProp('magazineSize');
      this.reloadTimer = this.getProp('reloadTimer');
      this.shotTimer = 0;
    }

    if (this.getProp('bulletAmount') > 1)
      for (const bulletIndex of range(1, this.getProp('bulletAmount'))) {
        const bulletAngle: number =
          (this.getProp('bulletSpread') / this.getProp('bulletAmount')) *
          (bulletIndex - 1);

        InstancesPool.add(
          new Bullet({
            x: this.getTipX(),
            y: this.getTipY(),
            speed: this.getProp('bulletSpeed'),
            direction: varyValue(
              this.direction - bulletAngle + bulletAngle / 2,
              this.getProp('dispersion')
            ),
            friction: this.getProp('bulletFriction'),
            sprite: this.getProp('bulletSprite'),
            angledSprite: true,
            owner: 'player',
          })
        );
      }
    // InstancesPool.add(
    //   new InstantBullet({
    //     x: this.getTipX(),
    //     y: this.getTipY(),
    //     direction: varyValue(this.direction, this.getProp('dispersion')),
    //   })
    // );
    else
      InstancesPool.add(
        new Bullet({
          x: this.getTipX(),
          y: this.getTipY(),
          speed: this.getProp('bulletSpeed'),
          direction: varyValue(this.direction, this.getProp('dispersion')),
          friction: this.getProp('bulletFriction'),
          sprite: this.getProp('bulletSprite'),
          angledSprite: true,
          owner: 'player',
        })
      );

    this.kickBack = this.getProp('kickBack') || 0;

    ParticlesManager.emit('hit-dust', {
      position: {
        x: this.getTipX(8),
        y: this.getTipY(8),
      },
      radius: 2,
    });

    ParticlesManager.emit('player-weapon-spark', {
      position: {
        x: this.getTipX(8),
        y: this.getTipY(8),
      },
      radius: 2,
    });

    Camera.shake(this.getProp('screenShake') * 2);
  }

  get position() {
    return { x: this.x, y: this.y };
  }

  draw(): void {
    this.sprite.zIndex = this.y;

    if (this.getProp('category') === 'ranged') this.drawRanged();
    else this.drawMelee();

    // this.drawDebug();
  }

  get facingDirection(): number {
    return app.renderer.plugins.interaction.mouse.global.x >=
      Camera.viewport.toGlobal({ x: this.x, y: this.y }).x
      ? 1
      : -1;
  }

  drawMelee(): void {
    this.sprite.angle = this.direction;
    this.sprite.x = this.x + lengthDirX(this.getProp('width'), this.direction);
    this.sprite.y =
      InstancesPool.p1.centerY + lengthDirY(4, this.direction) * this.swing;
    this.sprite.scale.x = this.scale;
    this.sprite.scale.y = this.scale * -this.facingDirection * this.swing;
  }

  drawRanged(): void {
    this.sprite.angle = this.direction;
    this.sprite.pivot.set(0, 0);
    this.sprite.x = this.x - this.kickBack * this.facingDirection;
    this.sprite.y = this.y - this.getProp('centerY');
    this.sprite.scale.x = this.scale;
    this.sprite.scale.y = this.scale * this.facingDirection;
  }

  drawDebug(): void {
    // Draw the weapon position coordinates
    Gfx.beginFill(0xdeff49, 1);

    // center
    Gfx.drawRect(
      this.x - this.kickBack * this.facingDirection - 2,
      this.y - 2,
      4,
      4
    );

    // Draw the weapon tip point
    Gfx.beginFill(0xde3249, 1);

    Gfx.drawRect(this.getTipX() - 1, this.getTipY() - 1, 4, 4);

    Gfx.endFill();
  }

  getTipX(offset?: number): number {
    return (
      this.x -
      this.kickBack * this.facingDirection +
      lengthDirX(this.getProp('width') + (offset || 0), this.direction)
    );
  }

  getTipY(offset?: number): number {
    return (
      this.y + lengthDirY(this.getProp('width') + (offset || 0), this.direction)
    );
  }

  destroy(): void {
    window.removeEventListener('mousedown', this.handleMouseDown);
    window.removeEventListener('mouseup', this.handleMouseUp);

    this.destroyed = true;
  }
}
