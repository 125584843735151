import gfx from '../core/gfx';
import InstancesPool from '../core/InstancesPool';
import FloorDecoration from './FloorDecoration';
import GameMap from './GameMap';
import WallDecoration from './WallDecoration';

export default class DecorationSpawner {
  ID: string =
    'DecorationSpawner_' + Math.ceil(Math.random() * Date.now()).toString(36);
  public destroyed: boolean = false;
  private spawnTime: number = Date.now() + 1 * 1000;
  public width: number = 10;
  public height: number = 10;
  public hasPhysics: boolean = false;

  constructor(
    public readonly x: number,
    public readonly y: number,
    private readonly spawnID: string,
    public readonly spritePath: string,
    public readonly offsetX: number,
    public readonly offsetY: number,
    private readonly parentMap: GameMap
  ) {}

  get position() {
    return { x: this.x, y: this.y };
  }

  update(): void {
    if (Date.now() >= this.spawnTime) {
      this.spawnEntity();
      this.destroy();
    }

    this.drawDebug();
  }

  drawDebug(): void {
    gfx.beginFill(0x00fa00, 1);
    gfx.drawRect(this.x, this.y, this.width, this.height);
    gfx.endFill();
  }

  spawnEntity(): void {
    let spawnedEntity;

    switch (this.spawnID) {
      case 'FloorDecoration': {
        spawnedEntity = new FloorDecoration(
          this.x,
          this.y,
          this.spritePath,
          this.offsetX,
          this.offsetY
        );
        break;
      }
      case 'WallDecoration': {
        spawnedEntity = new WallDecoration(
          this.x,
          this.y,
          this.spritePath,
          this.offsetX,
          this.offsetY
        );
        break;
      }
      default: {
        console.log(`SPAWN ${this.spawnID}`);
      }
    }

    if (spawnedEntity) {
      InstancesPool.add(spawnedEntity);
      this.parentMap.registerObject(spawnedEntity);
    }
  }

  destroy(): void {
    this.destroyed = true;
  }
}
