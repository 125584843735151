export default Object.seal({
  Dirt: {
    walls: [
      {
        sprite: 'assets/sprites/decorations/spr_decoration_dirt_wall_2.png',
        offsetX: 18,
        offsetY: 40,
        hp: -1,
      },
      {
        sprite: 'assets/sprites/decorations/spr_decoration_dirt_wall_3.png',
        offsetX: 12,
        offsetY: 16,
        hp: -1,
      },
    ],
    floors: [
      {
        sprite: 'assets/sprites/decorations/spr_decoration_dirt_floor_1.png',
        offsetX: 5,
        offsetY: 4,
        hp: -1,
      },
      {
        sprite: 'assets/sprites/decorations/spr_decoration_dirt_floor_2.png',
        offsetX: 8,
        offsetY: 8,
        hp: -1,
      },
      {
        sprite: 'assets/sprites/decorations/spr_decoration_dirt_floor_3.png',
        offsetX: 8,
        offsetY: 8,
        hp: 4,
      },
    ],
  },
  Desert: {
    walls: [
      {
        sprite: 'assets/sprites/decorations/spr_decoration_desert_wall_1.png',
        offsetX: 12,
        offsetY: 36,
        hp: -1,
      },
    ],
    floors: [
      {
        sprite: 'assets/sprites/decorations/spr_decoration_dirt_floor_1.png',
        offsetX: 5,
        offsetY: 4,
        hp: -1,
      },
    ],
  },
});

export type DecorationItem = {
  sprite: string;
  offsetX: number;
  offsetY: number;
  hp: number;
};
